/**
 * @file
 * A component displaying Trip Expense information
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, ViewStyle } from 'react-native';
import styled from '@emotion/native';
import _ from 'lodash';
import { FitToContentButton } from '@appComponents/Button';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import {
  FormatDollars,
  getExpenseTotals,
  groupExpenseAdjustments,
} from '@appUtils/accounting';
import { useStorageImages } from '@webUtils/storage';
import { useTheme } from '@appComponents/theme';
import ExpenseTable, {
  initialSort,
  titlePath,
} from '@webComponents/Accounting/ExpenseTable';
import ExportSection, {
  AdjustmentExportSection,
} from '@webComponents/Accounting/Exports';
import {
  AccountsPayable,
  AccountsReceivable,
} from '@webComponents/Accounting/Adjustments';
import {
  ExpensesApprovedPill,
  ExpensesCompletePill,
  HeaderText,
} from '@webComponents/Accounting/General';
import { useExpenseDialog } from '@webComponents/Accounting/ExpenseDialog';
import { useAircraftData } from '@appUtils/aircraft';
import { useCompanyData } from '@appUtils/api';
import { useTripExpensesHaveComments } from '@appUtils/expenseComments';

type TripExpensesProps = {
  trip: Object,
  save: Function,
  update: Function,
  style?: ViewStyle,
};

// TODO: Improve layout for iPad

const TripExpenses = ({ trip, save, update, style }: TripExpensesProps) => {
  const [aircraft] = useAircraftData(trip?.aircraft?.path);
  const [company] = useCompanyData();
  const [imagePaths, setImagePaths] = useState([]);
  const theme = useTheme();
  const tripName = `${trip?.identifier} - ${trip?.customName ?? ''}`;
  const expenses = useMemo(() => trip?.expenses ?? [], [trip?.expenses]);
  const expenseAdjustments = useMemo(
    () => trip?.expenseAdjustments ?? [],
    [trip?.expenseAdjustments],
  );
  const ownerName = trip?.owner
    ? `${trip?.owner?.firstName} ${trip?.owner?.lastName}`
    : '';
  const picName = trip?.pilots[0]
    ? `${trip?.pilots[0]?.firstName} ${trip?.pilots[0]?.lastName}`
    : '';
  const sicName = trip?.pilots[1]
    ? `${trip?.pilots[1]?.firstName} ${trip?.pilots[1]?.lastName}`
    : '';
  const images = useStorageImages(imagePaths);
  const {
    expenseTotal,
    totalDueFromOwner,
    totalDueToPilots,
    totalDueToPic,
    totalDueToSic,
  } = getExpenseTotals(expenses, picName, sicName);
  const { ownerAdjustments, picAdjustments, sicAdjustments } =
    groupExpenseAdjustments(expenseAdjustments, ownerName, picName, sicName);
  const picExpensesComplete = trip?.picExpensesComplete ?? false;
  const sicExpensesComplete = trip?.sicExpensesComplete ?? false;
  const expensesComplete = trip?.expensesComplete ?? false;
  const expensesSentToOwner = trip?.expensesSentToOwner ?? false;
  const expensesApproved = trip?.expensesApproved ?? false;
  const tripId = trip?.id;
  const tripExpensesHaveComments = useTripExpensesHaveComments(tripId);
  const [hasExpenseComments, setHasExpenseComments] = useState({});
  const [anyDialogOpen, setAnyDialogOpen] = useState(false);
  useEffect(() => {
    if (!anyDialogOpen) {
      if (!_.isEqual(tripExpensesHaveComments, hasExpenseComments)) {
        setHasExpenseComments(tripExpensesHaveComments);
      }
    }
  }, [anyDialogOpen, hasExpenseComments, tripExpensesHaveComments]);

  const [sort, setSort] = useState(initialSort);
  const [sortedExpenses, setSortedExpenses] = useState(expenses);
  const [expandedExpenses, setExpandedExpenses] = useState({});
  const sendToOwner = useCallback(() => {
    update({ expensesSentToOwner: true });
    save();
  }, [save, update]);
  const closeOrReopenExpenses = useCallback(() => {
    const updateValues = expensesComplete
      ? reopenExpenseValues
      : closeExpenseValues;
    update({ ...updateValues });
    save();
  }, [expensesComplete, save, update]);
  const updateSort = useCallback(
    newSort => {
      setSortedExpenses(
        _.orderBy(sortedExpenses, [titlePath[newSort.title]], [newSort.dir]),
      );
      setSort(newSort);
    },
    [sortedExpenses],
  );
  const { dialogNode, openDialog } = useExpenseDialog({
    mode: 'Add',
    saveTrip: save,
    updateTrip: update,
    currentExpenses: expenses,
    expenseCategories: company?.config?.expenseTypes ?? [],
    paymentMethods: aircraft?.paymentMethods ?? [],
    tripId: tripId,
    fuelUnits: aircraft?.fuelUnits ?? 'gal',
    setAnyDialogOpen,
  });

  useEffect(() => {
    const newSort = _.orderBy(expenses, [titlePath[sort.title]], [sort.dir]);
    if (!_.isEqual(newSort, sortedExpenses)) {
      setSortedExpenses(newSort);
    }
  }, [sort.dir, sort.title, expenses, sortedExpenses]);

  useEffect(() => {
    const paths = expenses.map(expense =>
      expense.photoUrls ? expense.photoUrls : [],
    );
    const flattenedPaths = _.flatten(paths);
    if (!_.isEqual(flattenedPaths, imagePaths)) {
      setImagePaths(flattenedPaths);
    }
  }, [expenses, imagePaths]);

  return (
    <Box pv={1} style={style} ph={2}>
      <Spacer size={1.5} />
      {dialogNode}
      <ExpensesTopBar
        total={expenseTotal}
        expensesComplete={expensesComplete}
        picExpensesComplete={picExpensesComplete}
        sicExpensesComplete={sicExpensesComplete}
        expensesApproved={expensesApproved}
        openDialog={openDialog}
      />
      <Spacer />
      <SendAndExportRow
        data={expenses}
        tripName={tripName}
        showSendToOwner={expensesComplete && !expensesSentToOwner}
        sendToOwner={sendToOwner}
        expensesComplete={expensesComplete}
        closeOrReopenExpenses={closeOrReopenExpenses}
        images={images}
        fuelUnits={aircraft?.fuelUnits ?? 'gal'}
      />
      <ExpenseTable
        unsortedExpenses={expenses}
        sortedExpenses={sortedExpenses}
        updateSort={updateSort}
        expandedExpenses={expandedExpenses}
        setExpandedExpenses={setExpandedExpenses}
        images={images}
        updateTrip={update}
        saveTrip={save}
        company={company}
        aircraft={aircraft}
        tripId={tripId}
        tripExpenseComments={hasExpenseComments}
        setAnyDialogOpen={setAnyDialogOpen}
      />
      <Spacer size={1} />
      <Row jc="flex-end">
        <AdjustmentExportSection trip={trip} tripName={tripName} />
      </Row>
      <Spacer size={1} />
      <Row>
        <AccountsPayable
          theme={theme}
          pilots={trip?.pilots}
          picName={picName}
          sicName={sicName}
          totalDueToPilots={totalDueToPilots}
          totalDueToPic={totalDueToPic}
          totalDueToSic={totalDueToSic}
          existingAdjustments={expenseAdjustments}
          picAdjustments={picAdjustments}
          sicAdjustments={sicAdjustments}
          picExpensesComplete={picExpensesComplete}
          sicExpensesComplete={sicExpensesComplete}
          update={update}
          save={save}
        />
        <AccountsReceivable
          theme={theme}
          owner={trip?.owner}
          ownerName={ownerName}
          totalDueFromOwner={totalDueFromOwner}
          existingAdjustments={expenseAdjustments}
          ownerAdjustments={ownerAdjustments}
          update={update}
          save={save}
        />
      </Row>
    </Box>
  );
};

const closeExpenseValues = {
  expensesComplete: true,
};

const reopenExpenseValues = {
  expensesComplete: false,
  picExpensesComplete: false,
  sicExpensesComplete: false,
  expensesSentToOwner: false,
  expensesApproved: false,
};

const ExpensesTopBarWrapper = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  height: '54px',
  alignItems: 'center',
  justifyContent: 'left',
  paddingLeft: '15px',
  backgroundColor: theme.colors.background,
  borderRadius: theme.roundness,
}));

const ExpensesTopBar = ({
  total,
  expensesComplete,
  picExpensesComplete,
  sicExpensesComplete,
  expensesApproved,
  openDialog,
}) => (
  <ExpensesTopBarWrapper>
    <Row jc="left" w={'90%'}>
      <HeaderText>
        TOTAL TRIP EXPENSE      {<FormatDollars dollars={total} />}
      </HeaderText>
      <ExpensesCompletePill
        expensesComplete={expensesComplete}
        picExpensesComplete={picExpensesComplete}
        sicExpensesComplete={sicExpensesComplete}
      />
      <ExpensesApprovedPill expensesApproved={expensesApproved} />
    </Row>
    <Row jc={'right'} w={'10%'} ph="10px">
      <FitToContentButton onPress={openDialog}>ADD EXPENSE</FitToContentButton>
    </Row>
  </ExpensesTopBarWrapper>
);

const Row = styled(View)(({ h, c, mt, ph, jc, w, theme }) => ({
  flexDirection: 'row',
  width: w ?? '100%',
  justifyContent: jc ?? 'space-between',
  alignItems: 'center',
  height: h ?? undefined,
  backgroundColor: c ?? undefined,
  marginTop: mt ?? undefined,
  paddingHorizontal: ph ?? undefined,
  borderRadius: theme.roundness,
}));

const SendToOwnerSpacer = styled(View)`
  width: 40%;
`;

const CloseOrReopenButton = ({ expensesComplete, closeOrReopenExpenses }) => (
  <FitToContentButton onPress={closeOrReopenExpenses}>
    {expensesComplete ? 'REOPEN EXPENSES' : 'CLOSE EXPENSES'}
  </FitToContentButton>
);

const SendAndExportRow = ({
  data,
  tripName,
  showSendToOwner,
  sendToOwner,
  expensesComplete,
  closeOrReopenExpenses,
  images,
  fuelUnits,
}) => (
  <Row>
    <SendToOwnerSpacer>
      {showSendToOwner && (
        <FitToContentButton onPress={sendToOwner}>
          Send Trip Expenses to Owner
        </FitToContentButton>
      )}
    </SendToOwnerSpacer>
    <Row jc={'right'} w={'60%'}>
      <CloseOrReopenButton
        expensesComplete={expensesComplete}
        closeOrReopenExpenses={closeOrReopenExpenses}
      />
      <ExportSection
        data={data}
        tripName={tripName}
        images={images}
        fuelUnits={fuelUnits}
      />
    </Row>
  </Row>
);

export default TripExpenses;
