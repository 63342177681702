import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import Button from '@appComponents/Button';

const BackToButton = ({ backTo, params, ml, mt, mr }) => {
  const { navigate } = useNavigation();

  const backToCallback = useCallback(() => {
    navigate(backTo, params);
  }, [backTo, navigate, params]);

  return (
    <Button ml={ml} mt={mt} mr={mr} onPress={() => backToCallback()}>
      Back to {backTo}
    </Button>
  );
};

export default BackToButton;
