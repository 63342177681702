/**
 * @file
 * User related hooks and APIs
 *
 * @format
 * @flow strict-local
 */
import _ from 'lodash';

import app, { FieldPath, useCollection, useDocumentData } from '@appFirebase';
import { useCallback, useMemo } from 'react';

export const useUserData = id => {
  const ref = app.firestore().collection('users').doc(id);
  const [data, loading, error, snapshot] = useDocumentData(ref);

  const result = {
    ...data,
    id,
  };

  return [result, loading, error, snapshot];
};

export const useTripUserDocuments = userIds => {
  // set a dummy ids value so the query can run while user IDs are still loading
  let ids = ['1'];
  if (!_.isEmpty(userIds) && JSON.stringify(userIds) !== '[null]') {
    ids = userIds;
  }

  // WARNING: use of "in" is limited to 10; if there are more than 10 users on the trip
  // this will be a problem
  const query = app
    .firestore()
    .collection('users')
    .where(FieldPath.documentId(), 'in', ids);

  const [snapshot, , snapError] = useCollection(query);

  const data = useMemo(
    () =>
      _.map(snapshot?.docs, doc => {
        const path = doc.ref.path;
        const docData = doc.data();
        const name = `${docData?.firstName} ${docData?.lastName}`;

        return { ...docData, path, name };
      }),
    [snapshot?.docs],
  );

  return {
    loading: !snapshot && !snapError,
    error: snapError,
    data,
    snapshot,
  };
};

export const saveUserOwnerIds = (id, ownerIds) => {
  const ref = app.firestore().collection('users').doc(id);
  return ref.update({ ownerIds, dateUpdated: new Date() });
};

export const saveOwnerPassengerIds = ({ ownerId, passengerIds }) => {
  const ref = app.firestore().collection('users').doc(ownerId);
  return ref.update({ passengerIds, dateUpdated: new Date() });
};

export const saveUserDocuments = (id, documents) => {
  const ref = app.firestore().collection('users').doc(id);
  return ref.update('documents', documents);
};

export const usePassengers = ownerId => {
  const [user, loading] = useUserData(ownerId);
  const passengers = _.get(user, 'passengers', []);

  const updatePassenger = useCallback(
    (updates, passengerIndex) => {
      const updatedPassengers = passengers.map((originalData, index) => {
        if (passengerIndex === index) {
          return {
            ...originalData,
            ...updates,
          };
        }

        return originalData;
      });

      return app
        .firestore()
        .collection('users')
        .doc(ownerId)
        .update('passengers', updatedPassengers);
    },
    [ownerId, passengers],
  );

  return { passengers, updatePassenger, loading };
};
