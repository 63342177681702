/**
 * These are the colors for the web app.
 * There are a few other files that don't pull from this palette:
 *  - Global: modules/web/webapp.css
 *  - Messages: modules/web/components/TripMessages/tripMessages.css
 *  - Calendar: modules/web/screens/main/Calendar/styles.css
 */
const makePalette = colorScheme => {
  /* So far we're just storing the color scheme but in the future we can
   * use it to create a different palette */
  return {
    colorScheme,
    primary: '#0094FF',
    secondary: 'red',
    // secondary: '#5AFFC7',
    accent: '#0094FF',
    mobilePrimary: '#40AFED',
    highlight: '#FFC857',
    text: '#FFFFFF',
    dark: '#FFFFFF',
    background: '#0E102F',
    sidebarBackground: '#1A1D43',
    sidebarBorder: '#292455',
    notification: '#FF8024',
    disabled: '#949494',
    tableBackground: '#1A1D43',
    row: '#DAEFFC',
    selectedRow: '#252B50',
    error: '#DB3A34',
    heading: '#D0D2D6',
    header: '#0D0A2E',
    fieldBackground: '#2B2955',
    fieldBorder: '#818C99',
    placeholder: '#818C99',
    inactive: '#98A1BD',
    accepted: '#7CB518',
    updated: '#7B61FF',
    addImage: '#C4C4C4',
    white: '#FFFFFF',
    messageDark: '#0E102F',
    messageLight: '#252B50',
    messageSelected: '#384178',
    surface: '#1A1D43',
    onSurface: '#FFFFFF',
    squawkUFW: '#DB3A34',
    squawkUD: '#A9A9A9',
    squawkMEL: '#FFC857',
    squawkNS: '#7CB518',
  };
};

export default makePalette;
