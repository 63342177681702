/**
 * @file
 * A component displaying user totals based on Expenses and Adjustments
 *
 * @format
 * @flow strict-local
 */
import React, { useState } from 'react';
import Text from '@appComponents/Text';
import { View } from 'react-native';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import styled from '@emotion/native';
import { Divider, List } from 'react-native-paper';
import { FormatDollars } from '@appUtils/accounting';
import { useTheme } from '@appComponents/theme';
import { useNavigation } from '@react-navigation/native';
import { ExpansionIcon, HeaderText } from '@webComponents/Accounting/General';

const AccountingUsers = ({
  totalDueText,
  totalDue,
  userTotals,
  userDueText,
  tab,
}) => {
  const { navigate } = useNavigation();
  return (
    <Box pv={1} ph={2}>
      <TopBarWrapper>
        <HeaderText color="primary" weight={'bold'} size={'medium'}>
          {totalDueText}      {<FormatDollars dollars={totalDue} />}
        </HeaderText>
      </TopBarWrapper>
      {Object.keys(userTotals).map(key => (
        <UserTripTotal
          userTotal={userTotals[key]}
          navigate={navigate}
          userDueText={userDueText}
          tab={tab}
        />
      ))}
    </Box>
  );
};

const TripExpenseRow = ({
  tripName,
  tripTotal,
  theme,
  navigate,
  tripPath,
  tab,
}) => {
  return (
    <>
      <Row
        pl={theme.layout.space(1)}
        h={theme.layout.space(3)}
        bc={'background'}
        onClick={() => {
          navigate('Trip', {
            documentPath: tripPath,
            title: tripName,
            tab: 'EXPENSES',
            from: {
              name: 'Accounting',
              tab: tab,
            },
          });
        }}
        c={'pointer'}>
        <Row w={'70%'} jc={'flex-start'} c={'pointer'}>
          <Text size={'medium'}>{tripName}</Text>
        </Row>
        <Row w={'30%'} jc={'flex-start'} c={'pointer'}>
          <FormatDollars
            dollars={tripTotal}
            renderText={value => <Text size={'medium'}>{value}</Text>}
          />
        </Row>
      </Row>
      <Spacer size={0.25} />
    </>
  );
};

const UserAccordion = styled(List.Accordion)(({ theme }) => ({
  paddingBottom: '10px',
  backgroundColor: theme.colors.tableBackground,
}));

const UserTripTotal = ({ userTotal, navigate, userDueText, tab }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  if (!userTotal) {
    return null;
  }
  const { total, userName, ...trips } = userTotal;
  const tripNames = Object.keys(trips);
  const handlePress = () => setExpanded(!expanded);

  return (
    <>
      <Spacer />
      <UserAccordion
        title={
          expanded ? (
            <HeaderText>{userName}</HeaderText>
          ) : (
            <Row>
              <Row w={'70%'} jc={'flex-start'}>
                <HeaderText>{userName}</HeaderText>
              </Row>
              <Row w={'30%'} jc={'flex-start'}>
                <FormatDollars
                  dollars={total}
                  renderText={value => (
                    <HeaderText size={'medium'}>Total Due    {value}</HeaderText>
                  )}
                />
              </Row>
            </Row>
          )
        }
        left={() => null}
        right={() => (
          <ExpansionIcon name={expanded ? 'menu-up' : 'menu-down'} />
        )}
        expanded={expanded}
        onPress={handlePress}>
        <>
          <Divider />
          <Spacer />
          <Row>
            <Column w={'1%'} />
            <Column w={'99%'} ai={'left'}>
              <Row pl={theme.layout.space(1)} w={'60%'}>
                <Row w={'70%'} jc={'flex-start'}>
                  <Text size={'medium'} weight={600}>
                    Trip Name
                  </Text>
                </Row>
                <Row w={'30%'} jc={'flex-start'}>
                  <Text size={'medium'} weight={600}>
                    {userDueText}
                  </Text>
                </Row>
              </Row>
              <Spacer size={0.25} />
              <Column w={'60%'}>
                {tripNames.map(tripName => (
                  <TripExpenseRow
                    tripName={tripName}
                    tripTotal={trips[tripName].total}
                    navigate={navigate}
                    theme={theme}
                    tripPath={trips[tripName].path}
                    tab={tab}
                  />
                ))}
                <Row
                  pl={theme.layout.space(1)}
                  h={theme.layout.space(3)}
                  bc={'primary'}>
                  <Row w={'70%'} jc={'flex-start'}>
                    <Text size={'medium'} weight={600}>
                      Total
                    </Text>
                  </Row>
                  <Row w={'30%'} jc={'flex-start'}>
                    <FormatDollars
                      dollars={total}
                      renderText={value => (
                        <Text size={'medium'} weight={600}>
                          {value}
                        </Text>
                      )}
                    />
                  </Row>
                </Row>
              </Column>
            </Column>
          </Row>
        </>
      </UserAccordion>
      {!expanded && <Divider />}
    </>
  );
};

const Row = styled(View)(({ w, bc, h, jc, pl, c, theme }) => ({
  flexDirection: 'row',
  width: w ?? '100%',
  justifyContent: jc ?? 'space-evenly',
  alignItems: 'center',
  backgroundColor: theme.colors[bc] ?? undefined,
  height: h ?? undefined,
  paddingLeft: pl ?? 0,
  borderRadius: theme.roundness,
  cursor: c ?? 'auto',
}));

const Column = styled(View)(({ w, ai, ml }) => ({
  flexDirection: 'column',
  width: w ?? 'auto',
  justifyContent: 'space-around',
  alignItems: ai ?? 'center',
}));

const TopBarWrapper = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  height: '50px',
  alignItems: 'center',
  justifyContent: 'left',
  paddingLeft: '15px',
  backgroundColor: theme.colors.background,
  borderRadius: theme.roundness,
}));

export default AccountingUsers;
