/**
 * @file
 * A component displaying Accounting Set Up
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { View, ViewStyle } from 'react-native';
import { Divider, List } from 'react-native-paper';
import _ from 'lodash';
import styled from '@emotion/native';

import Button from '@appComponents/Button';
import Radio from '@appComponents/Radio';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { Icon } from '@appComponents/theme';
import { updateAircraft, useAircraftList } from '@appUtils/aircraft';
import { useCompanyData, useMyCompanyRef } from '@appUtils/api';
import { updateCompanyConfig } from '@appUtils/manager';
import DropDownRadioButton from '@webComponents/DropDownRadioButton';
import { TextFormField } from '@appComponents/forms/FormFields';
import { useTheme } from '@appComponents/theme';
import { ExpansionIcon } from '@webComponents/Accounting/General';
import { UserRole } from '@appUtils/tripConverter';

type AccountingSetUpProps = {
  style?: ViewStyle,
};

const AccountingSetUp = ({ style }: AccountingSetUpProps) => {
  const [companyRef] = useMyCompanyRef();
  const [company] = useCompanyData();
  const { data: aircraft } = useAircraftList();
  const companyConfig = useMemo(() => company?.config ?? {}, [company?.config]);
  const categories = companyConfig.expenseTypes ?? [];
  const { control, handleSubmit, reset } = useForm({
    defaultValues: companyConfig,
  });
  const [addingNew, setAddingNew] = useState(false);

  const { isSubmitSuccessful } = useFormState({ control });

  useEffect(() => {
    reset(companyConfig);
  }, [companyConfig, isSubmitSuccessful, reset]);

  return (
    <Box pv={1} style={style} ph={2}>
      <HeaderText>EXPENSE CATEGORIES</HeaderText>
      <SectionDivider />
      <CategorySectionWrapper>
        {categories.map((category, i) => (
          <CategoryItem
            category={category}
            index={i}
            categoryLength={categories.length}
            control={control}
            companyConfig={companyConfig}
            companyRef={companyRef}
            handleSubmit={handleSubmit}
          />
        ))}
        {addingNew && (
          <CategoryItem
            category={{ name: '', fuelExpense: false }}
            index={-1}
            categoryLength={categories.length}
            control={control}
            companyConfig={companyConfig}
            companyRef={companyRef}
            handleSubmit={handleSubmit}
            setAddingNew={setAddingNew}
          />
        )}
      </CategorySectionWrapper>
      <CenteredButton onPress={() => setAddingNew(true)} disabled={addingNew}>
        <Text>Add Expense Category</Text>
      </CenteredButton>
      <Spacer />
      <HeaderText>PAYMENT METHODS</HeaderText>
      <SectionDivider />
      {aircraft.map(a => (
        <AircraftPaymentMethods aircraft={a} />
      ))}
    </Box>
  );
};

const FuelExpenseRadios = ({ isFuel, setIsFuel }) => {
  const theme = useTheme();
  return (
    <Column>
      <FuelExpenseText>Fuel Expense</FuelExpenseText>
      <WidthlessRow>
        <FuelRadio
          value={true}
          label={'Yes'}
          checked={isFuel}
          onChange={() => setIsFuel(true)}
          style={radioStyle({ theme })}
        />
        <FuelRadio
          value={false}
          label={'No'}
          checked={!isFuel}
          onChange={() => setIsFuel(false)}
          style={radioStyle({ theme })}
        />
      </WidthlessRow>
    </Column>
  );
};

const CategoryItem = ({
  category,
  index,
  categoryLength,
  control,
  companyConfig,
  companyRef,
  handleSubmit,
  setAddingNew,
}) => {
  const [editingName, setEditingName] = useState(index > -1 ? false : true);
  const [isFuel, setIsFuel] = useState(category.fuelExpense);
  const newIndex = index > -1 ? index : categoryLength;
  const expenseCategoryCallback = useExpenseCategoryCallback({
    index,
    newIndex,
    setEditingName,
    setAddingNew,
    isFuel,
    companyRef,
    companyConfig,
  });

  const deleteExpenseCategory = useDeleteExpenseCategory({
    companyConfig,
    index,
    companyRef,
  });

  const fieldStyle = { width: '85%' };

  return (
    <CategoryItemWrapper>
      {editingName ? (
        <>
          <Section w={'47%'}>
            <ExpenseCategoryField
              name={`expenseTypes[${newIndex}].name`}
              control={control}
              style={fieldStyle}
            />
          </Section>
          <Section w={'30%'}>
            <FuelExpenseRadios isFuel={isFuel} setIsFuel={setIsFuel} />
          </Section>
          <Section w={'23%'}>
            <Button
              icon={'check'}
              mr={0.5}
              onPress={handleSubmit(expenseCategoryCallback)}
            />
            <Button
              color={'secondary'}
              icon={'close'}
              onPress={() => {
                setEditingName(false);
                if (index < 0) {
                  setAddingNew(false);
                }
              }}
            />
          </Section>
        </>
      ) : (
        <>
          <Section w={'77%'}>
            <CategoryText>{category.name}</CategoryText>
            {category.fuelExpense && <FuelIcon name="fuel" />}
          </Section>
          <Section w={'23%'}>
            <Button
              icon={'edit'}
              mr={0.5}
              onPress={() => setEditingName(true)}
            />
            <Button
              color={'secondary'}
              icon={'bin'}
              onPress={handleSubmit(deleteExpenseCategory)}
            />
          </Section>
        </>
      )}
    </CategoryItemWrapper>
  );
};

const PaymentMethod = ({
  method,
  index,
  options,
  control,
  aircraft,
  handleSubmit,
  setAddingNew,
}) => {
  const Manager = { role: UserRole.MANAGER, label: '', value: 'Manager' };
  const [billedTo, setBilledTo] = useState({
    role: method?.billedToRole ?? UserRole.MANAGER,
    label: method?.billedToName ?? '',
    value: method?.billedToName ?? 'Manager',
  });
  const [editingName, setEditingName] = useState(index > -1 ? false : true);
  const paymentMethodCallback = usePaymentMethodCallback({
    aircraft,
    index,
    setEditingName,
    setAddingNew,
    billedTo,
  });
  const deletePaymentMethod = useDeletePaymentMethod({
    aircraft,
    index,
    setAddingNew,
  });

  useEffect(() => {
    if (index > -1) {
      const paymentMethods = aircraft?.paymentMethods ?? [];
      const paymentMethod = paymentMethods[index];
      if (
        paymentMethod.billedToRole !== billedTo.role ||
        paymentMethod.billedToName !== billedTo.value
      ) {
        paymentMethod.billedToRole = billedTo.role;
        paymentMethod.billedToName = billedTo.value;
        paymentMethods[index] = paymentMethod;
        updateAircraft({ paymentMethods }, aircraft.path);
      }
    }
  }, [aircraft.path, aircraft?.paymentMethods, billedTo, index]);

  const newIndex = index > -1 ? index : aircraft.paymentMethods?.length ?? 0;
  return (
    <Row>
      <LeftSection>
        {editingName ? (
          <>
            <Section w={'70%'}>
              <PaymentMethodField
                name={`paymentMethods[${newIndex}].name`}
                control={control}
              />
            </Section>
            <Button
              icon={'check'}
              mr={1}
              onPress={handleSubmit(paymentMethodCallback)}
            />
            <Button
              color={'secondary'}
              icon={'close'}
              onPress={() => {
                setEditingName(false);
                if (index < 0) {
                  setAddingNew(false);
                }
              }}
            />
          </>
        ) : (
          <>
            <Section w={'70%'}>
              <PaymentMethodText>{method?.name}</PaymentMethodText>
            </Section>
            <Button icon={'edit'} mr={1} onPress={() => setEditingName(true)} />
            <Button
              color={'secondary'}
              icon={'bin'}
              onPress={handleSubmit(deletePaymentMethod)}
            />
          </>
        )}
      </LeftSection>
      <RightSection>
        <Radio
          label={'Manager'}
          value={'Manager'}
          checked={billedTo.role === UserRole.MANAGER}
          onChange={() => setBilledTo(Manager)}
          control={control}
        />
        <DropDownRadioButton
          value={billedTo}
          valuePicker={pickDropDownValue}
          onChange={setBilledTo}
          options={options}
          checked={billedTo.role !== UserRole.MANAGER}
          control={control}
        />
      </RightSection>
    </Row>
  );
};

const pickDropDownValue = billedTo =>
  billedTo?.role !== UserRole.MANAGER ? billedTo : '';

const AircraftPaymentMethods = ({ aircraft }) => {
  const [expanded, setExpanded] = useState(false);
  const handlePress = () => setExpanded(!expanded);
  const [addingNew, setAddingNew] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: aircraft,
  });

  const { isSubmitSuccessful } = useFormState({ control });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(aircraft);
    }
  }, [aircraft, isSubmitSuccessful, reset]);

  const aircraftTitle = aircraft?.name
    ? `${aircraft.name} | ${aircraft.tailNumber}`
    : aircraft?.tailNumber;

  const billedToOptions = getBilledToOptions(aircraft);

  return (
    <>
      <AircraftAccordion
        title={aircraftTitle}
        right={() => null}
        left={() => <ExpansionIcon name={expanded ? 'menu-up' : 'menu-down'} />}
        expanded={expanded}
        onPress={handlePress}>
        <AircraftBox>
          <Spacer size={0.5} />
          <PaymentSectionWrapper>
            <PaymentSectionHeader />
            <Spacer size={1.5} />
            <>
              {aircraft?.paymentMethods?.map((method, i) => (
                <PaymentMethod
                  method={method}
                  index={i}
                  options={billedToOptions}
                  control={control}
                  aircraft={aircraft}
                  handleSubmit={handleSubmit}
                  setAddingNew={setAddingNew}
                  key={i}
                />
              ))}
            </>
            {addingNew && (
              <PaymentMethod
                method={{ name: '' }}
                index={-1}
                options={billedToOptions}
                control={control}
                aircraft={aircraft}
                handleSubmit={handleSubmit}
                setAddingNew={setAddingNew}
              />
            )}
            <Button onPress={() => setAddingNew(true)} disabled={addingNew}>
              <Text>Add Payment Method</Text>
            </Button>
          </PaymentSectionWrapper>
          <Spacer />
        </AircraftBox>
      </AircraftAccordion>
      <Spacer size={0.2} />
    </>
  );
};

const PaymentSectionHeader = () => (
  <Row>
    <LeftSection>
      <HeaderText>PAYMENT METHOD</HeaderText>
    </LeftSection>
    <RightSection>
      <HeaderText pl={25}>IS BILLED TO</HeaderText>
    </RightSection>
  </Row>
);

const getBilledToOptions = aircraft => {
  const ownerOptions = [
    { role: UserRole.OWNER, value: 'Owner', label: 'Owner' },
  ];
  const pilotOptions = [
    { role: UserRole.PILOT, value: 'Pilot', label: 'Pilot' },
  ];
  if (aircraft?.owners) {
    aircraft.owners.map(owner => {
      const ownerName = `${owner.firstName} ${owner.lastName}`;
      ownerOptions.push({
        role: UserRole.OWNER,
        value: ownerName,
        label: ownerName,
      });
    });
  }
  if (aircraft?.pilots) {
    aircraft.pilots.map(pilot => {
      const pilotName = `${pilot.firstName} ${pilot.lastName}`;
      pilotOptions.push({
        role: UserRole.PILOT,
        value: pilotName,
        label: pilotName,
      });
    });
  }

  return [
    { label: 'Owners', options: ownerOptions },
    { label: 'Pilots', options: pilotOptions },
  ];
};

const useDeletePaymentMethod = ({ aircraft, index, setAddingNew }) =>
  useCallback(() => {
    const paymentMethods = aircraft?.paymentMethods;
    if (paymentMethods[index]) {
      paymentMethods.splice(index, 1);
    } else {
      setAddingNew(false);
    }
    updateAircraft({ paymentMethods }, aircraft.path);
  }, [aircraft.path, aircraft?.paymentMethods, index, setAddingNew]);

const usePaymentMethodCallback = ({
  aircraft,
  index,
  setEditingName,
  setAddingNew,
  billedTo,
}) =>
  useCallback(
    payload => {
      const paymentMethod =
        index > -1
          ? payload.paymentMethods[index]
          : payload.paymentMethods.pop();
      if (!_.isEmpty(paymentMethod?.name)) {
        const paymentMethods = aircraft?.paymentMethods ?? [];
        if (index > -1) {
          paymentMethods[index] = paymentMethod;
        } else {
          paymentMethod.billedToRole = billedTo.role;
          paymentMethod.billedToName = billedTo.value;
          paymentMethods.push(paymentMethod);
        }
        updateAircraft({ paymentMethods }, aircraft.path);
        setEditingName(false);
        if (index < 0) {
          setAddingNew(false);
        }
      }
    },
    [
      aircraft.path,
      aircraft?.paymentMethods,
      billedTo,
      index,
      setAddingNew,
      setEditingName,
    ],
  );

const useDeleteExpenseCategory = ({ companyConfig, index, companyRef }) =>
  useCallback(() => {
    const config = companyConfig;
    config?.expenseTypes.splice(index, 1);
    updateCompanyConfig({ config, companyRef });
  }, [companyConfig, companyRef, index]);

const useExpenseCategoryCallback = ({
  index,
  newIndex,
  setEditingName,
  setAddingNew,
  isFuel,
  companyRef,
  companyConfig,
}) =>
  useCallback(
    payload => {
      const config = companyConfig;
      config.expenseTypes[newIndex] = payload.expenseTypes[newIndex];
      config.expenseTypes[newIndex].fuelExpense = isFuel;
      updateCompanyConfig({ config, companyRef });
      setEditingName(false);
      if (index < 0) {
        setAddingNew(false);
      }
    },
    [
      companyConfig,
      companyRef,
      index,
      isFuel,
      newIndex,
      setAddingNew,
      setEditingName,
    ],
  );

const CategorySectionWrapper = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginHorizontal: theme.layout.space(1),
}));

const CategoryItemWrapper = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  width: theme.layout.space(26),
  alignItems: 'center',
  height: '45px',
  marginBottom: theme.layout.space(0.5),
}));

const SectionDivider = styled(Divider)(({ theme }) => ({
  marginVertical: theme.layout.space(1),
}));

const HeaderText = styled.Text(({ theme, pl }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.medium,
  paddingLeft: pl ?? 0,
}));

const FuelExpenseText = styled.Text(({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.medium,
  marginBottom: theme.layout.space(-0.8),
}));

const CategoryText = styled.Text(({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.large,
  alignSelf: 'flex-start',
}));

const PaymentMethodText = styled.Text(({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.large,
  width: theme.layout.space(10),
}));

const Row = styled(View)(({ w }) => ({
  flexDirection: 'row',
  width: w ?? '100%',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

const Column = styled(View)(() => ({
  flexDirection: 'column',
  width: 'auto',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

const WidthlessRow = styled(View)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

const Section = styled(View)(({ w }) => ({
  flexDirection: 'row',
  width: w ?? '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const LeftSection = styled(View)(() => ({
  flexDirection: 'row',
  width: '40%',
  alignItems: 'center',
}));

const RightSection = styled(View)(() => ({
  flexDirection: 'row',
  width: '60%',
  alignItems: 'center',
}));

const FuelIcon = styled(Icon)(({ theme }) => ({
  marginLeft: theme.layout.space(1),
}));

const AircraftAccordion = styled(List.Accordion)`
  padding-bottom: 10px;
`;

const PaymentSectionWrapper = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginHorizontal: theme.layout.space(1),
}));

const AircraftBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.background,
}));

const FuelRadio = styled(Radio)(({ theme }) => ({
  width: theme.layout.space(9),
}));

const radioStyle = ({ theme }) => {
  return {
    width: theme.layout.space(5),
    marginLeft: theme.layout.space(-1),
  };
};

const CenteredButton = styled(Button)(({}) => ({
  height: '36px',
  justifyContent: 'center',
  width: 'fit-content',
}));

const ExpenseCategoryField = styled(TextFormField)(({}) => ({
  height: '45px',
  marginBottom: '-15px',
}));

const PaymentMethodField = styled(TextFormField)(({}) => ({
  height: '40px',
  marginBottom: '-15px',
}));

export default AccountingSetUp;
