/**
 * @file
 * General accounting components
 *
 * @format
 * @flow strict-local
 */
import React from 'react';
import { View } from 'react-native';
import styled from '@emotion/native';
import { Avatar } from 'react-native-paper';
import Text from '@appComponents/Text';
import { Icon } from '@appComponents/theme';

const labelStyle = { fontWeight: '600' };

const ExpensesCompleteIcon = ({}) => (
  <AvatarText
    label={'P'}
    bc={'squawkNS'}
    size={20}
    color="white"
    labelStyle={labelStyle}
  />
);

const ExpensesApprovedIcon = ({}) => (
  <AvatarText
    label={'O'}
    bc={'mobilePrimary'}
    size={20}
    color="white"
    labelStyle={labelStyle}
  />
);

export const ExpensesCompletePill = ({
  expensesComplete,
  picExpensesComplete,
  sicExpensesComplete,
}) => {
  if (!expensesComplete && !picExpensesComplete && !sicExpensesComplete) {
    return null;
  }
  let pillText = 'EXPENSES COMPLETED';
  // Only add the 1/2 text if expenses were not marked as complete by the manager
  // and only 1 of the 2 pilots has completed their expenses
  if (
    !expensesComplete &&
    ((picExpensesComplete && !sicExpensesComplete) ||
      (!picExpensesComplete && sicExpensesComplete))
  ) {
    pillText += ' (1/2)';
  }
  return <Pill icon={<ExpensesCompleteIcon />} text={pillText} />;
};

export const ExpensesApprovedPill = ({ expensesApproved }) =>
  expensesApproved ? (
    <Pill icon={<ExpensesApprovedIcon />} text="EXPENSES APPROVED" />
  ) : null;

const AvatarText = styled(Avatar.Text)(({ theme, bc }) => ({
  backgroundColor: theme.colors[bc],
}));

const PillWrapper = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  borderRadius: '100px',
  borderWidth: '1px',
  borderColor: 'white',
  marginLeft: theme.layout.space(0.6),
  paddingHorizontal: theme.layout.space(0.6),
  height: theme.layout.space(2.2),
  justifyContent: 'center',
  alignItems: 'center',
}));

const Pill = ({ icon, text }) => (
  <PillWrapper>
    {icon}
    <TextWrapper ml={10}>{text}</TextWrapper>
  </PillWrapper>
);

export const HeaderText = ({ size, ml, w, color, h, children }) => (
  <TextWrapper
    size={size ?? 'large'}
    weight={700}
    ml={ml}
    w={w}
    color={color ?? 'text'}
    h={h}>
    {children}
  </TextWrapper>
);

export const TextWrapper = styled(Text)(({ ml, w, h, aSelf }) => ({
  marginLeft: ml ?? 0,
  width: w ?? undefined,
  alignSelf: aSelf ?? undefined,
  height: h ?? undefined,
}));

export const ExpansionIcon = styled(Icon)`
  padding-horizontal: 14px;
`;
