/**
 * @file
 * A component displaying totals due to Pilots based on Expenses and Adjustments
 *
 * @format
 * @flow strict-local
 */
import React from 'react';
import { getUserTotals } from '@appUtils/accounting';
import { UserRole } from '@appUtils/tripConverter';
import { useAllNonArchivedTrips } from '@appUtils/manager';
import { useCompanyUsers } from '@appUtils/api';
import AccountingUsers from '@webComponents/Accounting/AccountingUsers';
import { AccountingTab } from '.';

const AccountingPilots = () => {
  const { data: trips } = useAllNonArchivedTrips({});
  const [pilots] = useCompanyUsers([
    UserRole.PILOT,
    UserRole.MANAGER,
    UserRole.EMPLOYEE,
  ]);
  const { totalDue, ...userTotals } = getUserTotals(
    trips,
    pilots,
    UserRole.PILOT,
  );
  const totalDueText = 'TOTAL DUE TO ALL PILOTS';
  const userDueText = 'Due To Pilot';
  return (
    <AccountingUsers
      totalDueText={totalDueText}
      totalDue={totalDue}
      userTotals={userTotals}
      userDueText={userDueText}
      tab={AccountingTab.PILOTS}
    />
  );
};

export default AccountingPilots;
