import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import styled from '@emotion/native';

import {
  Header,
  MainSurface,
  ScreenLayout,
  ScreenLoader,
} from '@appComponents/ScreenLayout';
import Tabs from '@appComponents/Tabs';
import AccountingOwners from './AccountingOwners';
import AccountingPilots from './AccountingPilots';
import AccountingSetUp from './AccountingSetUp';

const Accounting = ({ route }) => {
  const [activeTab, setActiveTab] = useState(
    route.params?.tab ?? AccountingTab.OWNERS,
  );

  const loading = false;

  return (
    <ScreenLayout alignItems="stretch">
      <AccountingHeader>
        <AccountingTabs activeTab={activeTab} onChange={setActiveTab} />
      </AccountingHeader>
      <ScrollView>
        <MainSurface>
          {loading && <ScreenLoader />}
          {!loading && activeTab === AccountingTab.OWNERS && (
            <AccountingOwners />
          )}
          {!loading && activeTab === AccountingTab.PILOTS && (
            <AccountingPilots />
          )}
          {!loading && activeTab === AccountingTab.SETUP && <AccountingSetUp />}
        </MainSurface>
      </ScrollView>
    </ScreenLayout>
  );
};

export const AccountingTab = {
  OWNERS: 'OWNERS',
  PILOTS: 'PILOTS',
  SETUP: 'SET UP',
};

const getAccounting = () => [
  { key: 'owners', title: AccountingTab.OWNERS },
  { key: 'pilots', title: AccountingTab.PILOTS },
  { key: 'setup', title: AccountingTab.SETUP },
];

const AccountingTabs = ({ activeTab, onChange }) => {
  const [routes] = useState(getAccounting);
  const handleChange = useCallback(
    ({ route }) => onChange(route.title),
    [onChange],
  );

  return (
    <Tabs
      tabIndex={routes.findIndex(tab => tab.title === activeTab)}
      onTabPress={handleChange}
      routes={routes}
      tabBarInline
    />
  );
};

const AccountingHeader = styled(Header)`
  padding-bottom: 0;
`;

export default Accounting;
