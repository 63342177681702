/**
 * @format
 * @flow strict-local
 */

import React, { Node } from 'react';
import styled from '@emotion/native';
import { useNavigation } from '@react-navigation/native';

import Button from '@appComponents/Button';
import AuthLayout from './AuthLayout';
import Text from '@appComponents/Text';
import { CreateAccountForm, CreateCompanyForm } from './CreateAccountForm';
import { Box } from '@appComponents/ScreenLayout';

const AccountPane = ({ title, infoText, buttons, FormComponent }): Node => {
  const { navigate } = useNavigation();
  return (
    <AuthLayout wide>
      <TitleWrap>
        <Title color="dark" size="large" weight={500}>
          {title}
        </Title>
        <Text>{infoText}</Text>
        <Box dir="row" jc="center">
          {buttons.map(btn => (
            <Button
              key={btn.label}
              mode="text"
              color="secondary"
              uppercase={false}
              onPress={() => navigate(btn.destination)}>
              {btn.label}
            </Button>
          ))}
        </Box>
      </TitleWrap>
      <FormComponent />
    </AuthLayout>
  );
};

export const CreateAccount = () => (
  <AccountPane
    title="Join a Management Company"
    infoText="Join an existing management company. If you don't have a management company make one first."
    buttons={[
      {
        label: 'Log In',
        destination: 'Login',
      },
      {
        label: 'Create Company',
        destination: 'Create Company',
      },
    ]}
    FormComponent={CreateAccountForm}
  />
);

export const CreateCompany = () => (
  <AccountPane
    title="Create Management Company"
    infoText="Create an account for you and your management company. If your management company already has an account you can join it."
    buttons={[
      {
        label: 'Log In',
        destination: 'Login',
      },
      {
        label: 'Join Company',
        destination: 'Join Company',
      },
    ]}
    FormComponent={CreateCompanyForm}
  />
);

const TitleWrap = styled.View(({ theme }) => ({
  marginBottom: theme.layout.space(1.5),
  paddingHorizontal: theme.layout.space(1),
}));

const Title = styled(Text)(({ theme }) => ({
  marginBottom: theme.layout.space(1.5),
}));
