import React from 'react';
import styled from '@emotion/native';

import { logOut } from '@appUtils/auth';
import { useMyData } from '@appUtils/api';
import Text, { Title } from '@appComponents/Text';

export const WaitingRoom = () => {
  const [user, loading] = useMyData();
  if (loading) {
    return (
      <WaitingWrapper>
        <WaitingText>Loading...</WaitingText>
      </WaitingWrapper>
    );
  }
  return (
    <WaitingWrapper>
      <LogOutLink color="primary" onPress={logOut} size="medium">
        Log Out
      </LogOutLink>
      {loading ? (
        <WaitingText>Loading...</WaitingText>
      ) : user.companyCreator ? (
        <>
          <WaitingText>
            Your management company portal is being processed. This may take up
            to two minutes.
          </WaitingText>
          <WaitingText>
            This screen will refresh automatically when your account is
            complete.
          </WaitingText>
        </>
      ) : (
        <WaitingText>
          Your manager must approve your account. Please check with them for
          status.
        </WaitingText>
      )}
    </WaitingWrapper>
  );
};

const WaitingWrapper = styled.View(({ theme }) => ({
  width: '100%',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.layout.space(1),
}));

const LogOutLink = styled(Text)(({ theme }) => ({
  alignSelf: 'flex-end',
  margin: theme.layout.space(2),
}));

const WaitingText = styled(Title)(({ theme }) => ({
  margin: theme.layout.space(3),
}));
