import { rawTimeZones } from '@vvo/tzdb';
import { DateTime } from 'luxon';
import { createDraft, getUserData } from '@appUtils/api';
import tripConverter, {
  TripState,
  OwnerState,
  UserRole,
} from './tripConverter';
import { Platform } from 'react-native';

export const saveTrip = async (payload, doc, skipDeparture = false) => {
  if (!doc) {
    const { id, firstName, lastName, phoneNumber } = await getUserData();

    doc = await createDraft({
      state: TripState.OWNER_DRAFT,
      owner: {
        id,
        firstName,
        lastName,
        phoneNumber,
        state: OwnerState.OWNER_DRAFT,
      },
      archived: false,
    });
  }

  const { legs, ...rest } = payload;
  rest.legs = skipDeparture ? legs : legs?.map(setDepartureDate);
  const data = tripConverter.toFirestore(rest);

  await doc.update(data);
  return doc;
};

const setDepartureDate = leg => {
  const { date, time, timeZone, ...rest } = leg;
  const zone = rawTimeZones.find(
    tz => tz.countryCode === 'US' && tz.abbreviation === timeZone,
  );

  const departureDate = DateTime.fromJSDate(leg.date)
    .setZone(zone?.name ?? 'local')
    .set({ hour: time.getHours(), minute: time.getMinutes(), second: 0 });

  return {
    ...rest,
    departureDate: departureDate,
  };
};

export const flightTimeToMinutes = timeStr => {
  if (!timeStr) {
    return 0;
  }

  const [hours = 0, minutes = 0] = timeStr.split(':').map(Number);
  return minutes + hours * 60;
};

export const minutesToFlightTime = (totalMinutes, format = 'implicit') => {
  if (!totalMinutes) {
    return '';
  }

  const hours = Math.floor(totalMinutes / 60);
  const minutes = (totalMinutes % 60).toString().padEnd(2, '0');

  if (format === 'explicit') {
    if (minutes > 0) {
      return `${hours}h ${minutes}m`;
    }

    return `${hours}h`;
  }

  return `${hours}:${minutes}`;
};

export const getTripStateName = (state, defaultText) => {
  switch (state) {
    case TripState.OWNER_DRAFT:
      return 'Trip Builder - Review';
    case TripState.OWNER_REQUEST:
      return 'Requested Trip';
    case TripState.DRAFT:
      return Platform.OS === 'web' ? 'Draft Trip' : 'Requested Trip';
    case TripState.UPCOMING:
      return 'Upcoming Trip';
    case TripState.ACTIVE:
      return 'Active Trip';
    case TripState.ENDED:
      return 'Completed Trip';
    case TripState.CANCELLED:
      return 'Cancelled Trip';
    default:
      return defaultText;
  }
};

export const isOwnerOnTrip = (trip, user) => {
  if (user?.role !== UserRole.OWNER) {
    return false;
  }
  const userName = `${user?.firstName} ${user?.lastName}`;
  const ownerName = `${trip?.owner?.firstName} ${trip?.owner?.lastName}`;
  return userName === ownerName;
};

export const isPicOnTrip = (trip, user) => {
  if (user?.role === UserRole.OWNER || user?.role === UserRole.PASSENGER) {
    return false;
  }
  const userName = `${user?.firstName} ${user?.lastName}`;
  const picName =
    trip.pilots && trip.pilots.length > 0
      ? `${trip.pilots[0]?.firstName} ${trip.pilots[0]?.lastName}`
      : '';
  return userName === picName;
};

export const isSicOnTrip = (trip, user) => {
  if (user?.role === UserRole.OWNER || user?.role === UserRole.PASSENGER) {
    return false;
  }
  const userName = `${user?.firstName} ${user?.lastName}`;
  const sicName =
    trip.pilots && trip.pilots.length > 1
      ? `${trip.pilots[1]?.firstName} ${trip.pilots[1]?.lastName}`
      : '';
  return userName === sicName;
};
