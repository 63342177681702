import * as React from 'react';
import type { Node } from 'react';
import styled from '@emotion/native';

import AuthImage from '../../../../assets/img/login-img.jpg';
import AppLogo from '../../../../assets/logo/app-logo.svg';

const AuthLayout = ({ children, wide = false }: AuthLayoutProps): Node => (
  <AuthContainer>
    <AuthContent>
      <Logo />
      <AuthContentInner wide={wide}>{children}</AuthContentInner>
    </AuthContent>

    <BannerWrap>
      <ImageBackground source={{ uri: AuthImage }} />
    </BannerWrap>
  </AuthContainer>
);

type AuthLayoutProps = {
  children: Node,
};

const AuthContainer = styled.View`
  align-items: stretch;
  justify-content: stretch;
  flex: 1;
  flex-direction: row;
`;

const AuthContent = styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: auto;
`;

const Logo = styled(AppLogo)(({ theme }) => ({
  width: 216,
  height: 72,
  minWidth: 216,
  minHeight: 72,
}));

const AuthContentInner = styled.View(({ theme, wide = false }) => ({
  width: '100%',
  height: 'auto',
  maxWidth: 400,
  marginTop: theme.layout.space(5),
}));

const BannerWrap = styled.View`
  flex: 1;
`;

const ImageBackground = styled.ImageBackground`
  flex: 1;
`;

export default AuthLayout;
