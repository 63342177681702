import React from 'react';

import { MainSurface, ScreenLayout } from '@appComponents/ScreenLayout';

import UserList from '@webComponents/UserList';

const UserRoleList = ({ role, withActions = false }) => (
  <ScreenLayout alignItems="stretch">
    <MainSurface>
      <UserList withActions={withActions} role={role} />
    </MainSurface>
  </ScreenLayout>
);

export default UserRoleList;
