/**
 * @file
 * A component displaying totals due from Owners based on Expenses and Adjustments
 *
 * @format
 * @flow strict-local
 */
import React from 'react';
import { getUserTotals } from '@appUtils/accounting';
import { UserRole } from '@appUtils/tripConverter';
import { useAllNonArchivedTrips } from '@appUtils/manager';
import { useCompanyUsers } from '@appUtils/api';
import AccountingUsers from '@webComponents/Accounting/AccountingUsers';
import { AccountingTab } from '.';

const AccountingOwners = () => {
  const { data: trips } = useAllNonArchivedTrips({});
  const [owners] = useCompanyUsers(UserRole.OWNER);
  const { totalDue, ...userTotals } = getUserTotals(
    trips,
    owners,
    UserRole.OWNER,
  );
  const totalDueText = 'TOTAL DUE FROM ALL OWNERS';
  const userDueText = 'Due From Owner';
  return (
    <AccountingUsers
      totalDueText={totalDueText}
      totalDue={totalDue}
      userTotals={userTotals}
      userDueText={userDueText}
      tab={AccountingTab.OWNERS}
    />
  );
};

export default AccountingOwners;
