/**
 * @format
 * @flow strict-local
 */

import React, { Node, useEffect } from 'react';
import styled from '@emotion/native';
import { ScrollView, Linking } from 'react-native';

import { supportEmail } from '@appUtils/app';

const Terms = ({ route, navigation }): Node => {
  const isMobile = route.params?.mobile;
  useEffect(() => {
    if (isMobile) {
      navigation.setOptions({ headerShown: false });
    }
  }, [navigation, isMobile]);

  return (
    <TermsView>
      <Layout ph={2} pv={1} mobile={isMobile}>
        {route.name === 'Privacy' ? <PrivacyContent /> : <TermsContent />}
      </Layout>
    </TermsView>
  );
};

const TermsView = styled(ScrollView)`
  height: 10px;
`;

const Layout = styled.View(({ theme, mobile }) => {
  if (mobile) {
    return {
      flex: 1,
      paddingHorizontal: theme.layout.space(2),
      paddingVertical: theme.layout.space(1),
      color: '#B4B7BD',
      backgroundColor: '#17153A',
      fontFamily: theme.fonts.light.fontFamily,
      lineHeight: 22,
    };
  }

  return {
    flex: 1,
    paddingHorizontal: theme.layout.space(2),
    paddingVertical: theme.layout.space(1),
    color: theme.colors.text,
  };
});

const ExternalLink = ({ to, label = to }) => {
  const clickHandler = e => {
    e.preventDefault();
    Linking.openURL(to);
  };
  return <ColoredLink onPress={clickHandler}>{label}</ColoredLink>;
};

const ColoredLink = styled.Text(({ theme }) => ({
  color: theme.colors.text,
  textDecorationLine: 'underline',
  fontWeight: 'bold',
}));

const SupportEmail = () => (
  <ExternalLink to={`mailto:${supportEmail}`} label={supportEmail} />
);

const businessAddress = '563 WEST BAY AREA BLVD #187, WEBSTER, TX 77598';
const businessCounty = 'Harris';
const businessState = 'Texas';
const arbitrationCounty = 'Harris';
const arbitrationState = 'Texas';
const lawState = 'Texas';

const TermsContent = () => (
  <>
    <p>FlightApp! Terms of Service</p>
    <p>Last Updated: 25 January 2022</p>
    <p>
      Welcome, and thank you for your interest in FlightApp! ("<b>FlightApp!</b>
      ", "<b>we</b>", or "<b>us</b>") and our mobile applications, websites, and
      other services provided by us (collectively, the "<b>Service</b>"). These
      Terms of Service are a legally binding contract between you and FlightApp!
      regarding your use of the Service.
    </p>
    <p>
      <b>PLEASE READ THE FOLLOWING TERMS CAREFULLY.</b>
    </p>
    <p>
      <b>
        BY CLICKING "I ACCEPT," OR BY DOWNLOADING, INSTALLING, OR OTHERWISE
        ACCESSING OR USING THE SERVICE
      </b>
      , YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR
      USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND
      CONDITIONS, INCLUDING FLIGHTAPP!'S PRIVACY POLICY (TOGETHER, THESE "
      <b>TERMS</b>"). IF YOU ARE NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS,
      THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE SERVICE. YOUR USE OF THE
      SERVICE, AND FLIGHTAPP!'S PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN
      AGREEMENT BY FLIGHTAPP! AND BY YOU TO BE BOUND BY THESE TERMS.
    </p>
    <p>
      <b>ARBITRATION NOTICE.</b> Except for certain kinds of disputes described
      in Section 16, you agree that disputes arising under these Terms will be
      resolved by binding, individual arbitration, and BY ACCEPTING THESE TERMS,
      YOU AND FLIGHTAPP! ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
      PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING. YOU AGREE TO
      GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend your rights under
      this contract (except for matters that may be taken to small claims
      court). Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a
      judge or jury. (See Section 16.)
    </p>
    <ul>
      <li>
        <b>1. FlightApp! Service Overview.</b> The Service is provided by
        FlightApp! to help pilots manage their reporting needs. The Service
        enables pilots to easily generate certain engine warranty and
        maintenance reports as well as expense reports.
      </li>
      <li>
        <b>2. Eligibility.</b> You must be at least 16 years old to use the
        Service. By agreeing to these Terms, you represent and warrant to us
        that: (a) you are at least 16 years old; (b) you have not previously
        been suspended or removed from the Service; and (c) your registration
        and your use of the Service is in compliance with any and all applicable
        laws and regulations. If you are an entity, organization, or company,
        the individual accepting these Terms on your behalf represents and
        warrants that they have authority to bind you to these Terms and you
        agree to be bound by these Terms.
      </li>
      <li>
        <b>3. Accounts and Registration.</b> To use the Service, you must
        register for an account. When you register for an account, you may be
        required to provide us with some information about yourself, such as
        your name, email address, and phone number. You agree that the
        information you provide to us is accurate and that you will keep it
        accurate and up-to-date at all times. When you register, you will be
        asked to provide a password. You are solely responsible for maintaining
        the confidentiality of your account and password, and you accept
        responsibility for all activities that occur under your account. If you
        believe that your account is no longer secure, then you must immediately
        notify us at&nbsp;
        <SupportEmail />.
      </li>
      <li>
        <b>4. General Payment Terms.</b> Certain features of the Service may
        require you to pay fees. Before you pay any fees, you will have an
        opportunity to review and accept the fees that you will be charged. All
        fees are in U.S. Dollars and are non-refundable.
      </li>
      <ul>
        <li>
          <b>4.1 Authorization.</b> You authorize FlightApp! to charge all sums
          for the orders that you make and any level of Service you select as
          described in these Terms or published by FlightApp!, including all
          applicable taxes, to the payment method specified in your account
          (including recurring charges through the Apple App Store). If you pay
          any fees with a credit card, FlightApp! may seek pre-authorization of
          your credit card account prior to your purchase to verify that the
          credit card is valid and has the necessary funds or credit available
          to cover your purchase.
        </li>
        <li>
          <b>4.2 Subscription Service.</b> Upon registration for the Service,
          FlightApp! may offer a thirty (30) day trial period (the "
          <b>Trial Period</b>") for the Service, during which time you will not
          be charged. If you do not cancel your subscription prior to the end of
          the Trial Period, or if the Service does not include a Trial Period,
          you will be automatically charged recurring payments for the Service
          (the "<b>Subscription Service</b>"). By activating a Subscription
          Service, or by not cancelling prior to the end of the Trial Period you
          authorize FlightApp! to periodically charge, on a going-forward basis
          and until cancellation of either the recurring payments or your
          account, all accrued sums on or before the payment due date for the
          accrued sums. The "<b>Subscription Billing Date</b>" is the date when
          you purchase your first subscription to the Service or, if there is
          Trial Period, the day the Trial Period expires. Your account will be
          charged automatically on the Subscription Billing Date all applicable
          fees and taxes for the next subscription period. The subscription will
          continue unless and until you cancel your subscription or we terminate
          it. You must cancel your subscription before it renews in order to
          avoid billing of the next periodic subscription fee to your account.
          We will bill the periodic subscription fee to the payment method you
          provide to us during registration (or to a different payment method if
          you change your payment information). You may cancel your subscription
          via the methods below based on how you acquired your subscription:
          <p>
            <b>iOS</b>
          </p>
          <p>
            Subscriptions acquired via our iOS app are managed through your
            Apple account. Please see the below article from Apple to learn how
            to manage or cancel a recurring subscription:&nbsp;
            <ExternalLink to="https://support.apple.com/en-us/HT202039" />
          </p>
        </li>
        <li>
          <b>4.3 Delinquent Accounts.</b> FlightApp! may suspend or terminate
          access to the Service, including fee-based portions of the Service,
          for any account for which any amount is due but unpaid.
        </li>
      </ul>
      <li>
        <b>5. Licenses</b>
      </li>
      <ul>
        <li>
          <b>5.1 Limited License.</b> Subject to your complete and ongoing
          compliance with these Terms, FlightApp! grants you, solely for your
          personal, non-commercial use, a limited, non-exclusive,
          non-transferable, non-sublicensable, revocable license to: (a) install
          and use one object code copy of any mobile application associated with
          the Service obtained from a legitimate marketplace (whether installed
          by you or pre-installed on your mobile device by the device
          manufacturer) on a mobile device that you own or control; and (b)
          access and use the Service.
        </li>
        <li>
          <b>5.2 License Restrictions.</b> Except and solely to the extent such
          a restriction is impermissible under applicable law, you may not: (a)
          reproduce, distribute, publicly display, or publicly perform the
          Service; (b) make modifications to the Service; or (c) interfere with
          or circumvent any feature of the Service, including any security or
          access control mechanism. If you are prohibited under applicable law
          from using the Service, you may not use it.
        </li>
        <li>
          <b>5.3 Feedback.</b> If you choose to provide input and suggestions
          regarding problems with or proposed modifications or improvements to
          the Service ("<b>Feedback</b>"), then you hereby grant FlightApp! an
          unrestricted, perpetual, irrevocable, non-exclusive, fully-paid,
          royalty-free right to exploit the Feedback in any manner and for any
          purpose, including to improve the Service and create other products
          and services.
        </li>
      </ul>
      <li>
        <b>6. Ownership; Proprietary Rights.</b> The Service is owned,
        copyrighted and operated by FlightApp!. The visual interfaces, graphics,
        design, compilation, information, data, computer code (including source
        code or object code), products, software, services, and all other
        elements of the Service ("<b>Materials</b>") provided by FlightApp! are
        protected by intellectual property and other laws. All Materials
        included in the Service are the property of FlightApp! or its third
        party licensors. Except as expressly authorized by FlightApp!, you may
        not make use of the Materials. FlightApp! reserves all rights to the
        Materials not granted expressly in these Terms.
      </li>
      <li>
        <b>7. Third Party Terms</b>
      </li>
      <ul>
        <li>
          <b>7.1 Third Party Services and Linked Websites.</b> FlightApp! may
          provide tools through the Service that enable you to export
          information, including User Content, to third party services. By using
          one of these tools, you agree that FlightApp! may transfer that
          information to the applicable third party service. Third party
          services are not under FlightApp!'s control, and, to the fullest
          extent permitted by law, FlightApp! is not responsible for any third
          party services use of your exported information. The Service may also
          contain links to third party websites. Linked websites are not under
          FlightApp!'s control, and FlightApp! is not responsible for their
          content.
        </li>
        <li>
          <b>7.2 Third Party Software.</b> The Service may include or
          incorporate third party software components that are generally
          available free of charge under licenses granting recipients broad
          rights to copy, modify, and distribute those components ("
          <b>Third Party Components</b>"). Although the Service is provided to
          you subject to these Terms, nothing in these Terms prevents,
          restricts, or is intended to prevent or restrict you from obtaining
          Third Party Components under the applicable third party licenses or to
          limit your use of Third Party Components under those third party
          licenses.
        </li>
      </ul>
      <li>
        <b>8. User Content</b>
      </li>
      <ul>
        <li>
          <b>8.1 User Content Generally.</b> Certain features of the Service may
          permit users to upload content to the Service, including reports,
          photos, video, images, folders, data, text, and other types of works
          ("<b>User Content</b>") and to publish User Content on the Service.
          You retain any copyright and other proprietary rights that you may
          hold in the User Content that you post to the Service.
        </li>
        <li>
          <b>8.2 Limited License Grant to FlightApp!.</b> By providing User
          Content to or via the Service, you grant FlightApp! a worldwide,
          non-exclusive, irrevocable, royalty-free, fully paid right and license
          (with the right to sublicense) to host, store, transfer, display,
          perform, reproduce, modify for the purpose of formatting for display,
          and distribute your User Content, in whole or in part, in any media
          formats and through any media channels now known or hereafter
          developed, for purposes of providing you the Service.
        </li>
        <li>
          <b>8.3 User Content Representations and Warranties.</b> FlightApp!
          disclaims any and all liability in connection with User Content.
          Subject to our rights to remove User Content in Section 8.4, we will
          host your User Content for a period of one (1) year from your upload
          of that User Content, after which time FlightApp! may remove your User
          Content at our discretion. You are solely responsible for your User
          Content and the consequences of providing User Content via the
          Service. You are responsible for periodically backing up any User
          Content that you provide. By providing User Content via the Service,
          you affirm, represent, and warrant that:
        </li>
        <ul>
          <li>
            a. you are the creator and owner of the User Content, or have the
            necessary licenses, rights, consents, and permissions to authorize
            FlightApp! to use and distribute your User Content as necessary to
            exercise the licenses granted by you in this Section, in the manner
            contemplated by FlightApp!, the Service, and these Terms;
          </li>
          <li>
            b. your User Content, and the use of your User Content as
            contemplated by these Terms, does not and will not: (i) infringe,
            violate, or misappropriate any third party right, including any
            copyright, trademark, patent, trade secret, moral right, privacy
            right, right of publicity, or any other intellectual property or
            proprietary right; (ii) slander, defame, libel, or invade the right
            of privacy, publicity or other property rights of any other person;
            or (iii) cause FlightApp! to violate any law or regulation; and
          </li>
          <li>
            c. your User Content could not be deemed by a reasonable person to
            be objectionable, profane, indecent, pornographic, harassing,
            threatening, embarrassing, hateful, or otherwise inappropriate.
          </li>
        </ul>

        <li>
          <b>8.4 User Content Disclaimer.</b> We are under no obligation to edit
          or control User Content that you or other users post or publish, and
          will not be in any way responsible or liable for User Content.
          FlightApp! may, however, at any time and without prior notice, screen,
          remove, edit, or block any User Content that in our sole judgment
          violates these Terms or is otherwise objectionable. You agree to
          waive, and do waive, any legal or equitable right or remedy you have
          or may have against FlightApp! with respect to User Content. If
          notified by a user or content owner that User Content allegedly does
          not conform to these Terms, we may investigate the allegation and
          determine in our sole discretion whether to remove the User Content,
          which we reserve the right to do at any time and without notice. For
          clarity, FlightApp! does not permit copyright-infringing activities on
          the Service.
        </li>
        <li>
          <b>8.5 Monitoring Content.</b> FlightApp! does not control and does
          not have any obligation to monitor: (a) User Content; (b) any content
          made available by third parties; or (c) the use of the Service by its
          users. You acknowledge and agree that FlightApp! reserves the right
          to, and may from time to time, monitor any and all information
          transmitted or received through the Service for operational and other
          purposes. If at any time FlightApp! chooses to monitor the content,
          FlightApp! still assumes no responsibility or liability for content or
          any loss or damage incurred as a result of the use of content. During
          monitoring, information may be examined, recorded, copied, and used in
          accordance with our Privacy Policy.
        </li>
      </ul>
      <li>
        <b>9. Communications.</b>
      </li>

      <ul>
        <li>
          <b>9.1 Push Notifications.</b> When you install our app on your mobile
          device, you agree to receive push notifications, which are messages an
          app sends you on your mobile device when the app is not on. You can
          turn off notifications by visiting your mobile devices settings page.
        </li>
        <li>
          <b>9.2 Email.</b> We may send you emails concerning our products and
          services, as well as those of third parties. You may opt out of
          promotional emails by following the unsubscribe instructions in the
          promotional email itself.
        </li>
      </ul>

      <li>
        <b>10. Prohibited Conduct.</b> BY USING THE SERVICE YOU AGREE NOT TO:
      </li>
      <ul>
        <li>
          a. use the Service for any illegal purpose or in violation of any
          local, state, national, or international law;
        </li>
        <li>
          b. violate, or encourage others to violate, any right of a third
          party, including by infringing or misappropriating any third party
          intellectual property right;
        </li>
        <li>
          c. interfere with security-related features of the Service, including
          by: (i) disabling or circumventing features that prevent or limit use
          or copying of any content; or (ii) reverse engineering or otherwise
          attempting to discover the source code of any portion of the Service
          except to the extent that the activity is expressly permitted by
          applicable law;
        </li>
        <li>
          d. interfere with the operation of the Service or any users enjoyment
          of the Service, including by: (i) uploading or otherwise disseminating
          any virus, adware, spyware, worm, or other malicious code; (ii) making
          any unsolicited offer or advertisement to another user of the Service;
          (iii) collecting personal information about another user or third
          party without consent; or (iv) interfering with or disrupting any
          network, equipment, or server connected to or used to provide the
          Service;
        </li>
        <li>
          e. perform any fraudulent activity including impersonating any person
          or entity, claiming a false affiliation, accessing any other Service
          account without permission;
        </li>
        <li>
          f. sell or otherwise transfer the access granted under these Terms or
          any Materials (as defined in Section 6) or any right or ability to
          view, access, or use any Materials; or
        </li>
        <li>
          g. attempt to do any of the acts described in this Section 10 or
          assist or permit any person in engaging in any of the acts described
          in this Section 10.
        </li>
      </ul>
      <li>
        <b>11. Modification of these Terms.</b> We reserve the right to change
        these Terms on a going-forward basis at any time. Please check these
        Terms periodically for changes. If a change to these Terms materially
        modifies your rights or obligations, we will email you the modified
        Terms in advance. Material modifications are effective upon your
        acceptance of the modified Terms (including by continued use of the
        Service). Immaterial modifications are effective upon publication.
        Except as expressly permitted in this Section 11, these Terms may be
        amended only by a written agreement signed by authorized representatives
        of the parties to these Terms. Disputes arising under these Terms will
        be resolved in accordance with the version of these Terms that was in
        effect at the time the dispute arose.
      </li>
      <li>
        <b>12. Term, Termination and Modification of the Service</b>
      </li>
      <ul>
        <li>
          <b>12.1 Term.</b> These Terms are effective beginning when you accept
          the Terms or first download, install, access, or use the Service, and
          ending when terminated as described in Section 12.2.
        </li>
        <li>
          <b>12.2 Termination.</b> If you violate any provision of these Terms,
          your authorization to access the Service and these Terms automatically
          terminate. In addition, FlightApp! may, at its sole discretion,
          terminate these Terms or your account on the Service, or suspend or
          terminate your access to the Service, at any time for any reason or no
          reason, with or without notice. You may terminate your account and
          these Terms at any time by contacting customer service at&nbsp;
          <SupportEmail />
          &nbsp;or otherwise following the instructions within the Service.
        </li>
        <li>
          <b>12.3 Effect of Termination.</b> Upon termination of these Terms:
          (a) your license rights will terminate and you must immediately cease
          all use of the Service; (b) you will no longer be authorized to access
          your account or the Service; (c) you must pay FlightApp! any unpaid
          amount that was due prior to termination; and (d) all payment
          obligations accrued prior to termination and Sections 5.3, 6, 8.2,
          12.3, 13, 14, 15, 16 and 17 will survive.
        </li>
        <li>
          <b>12.4 Modification of the Service.</b> FlightApp! reserves the right
          to modify or discontinue the Service at any time (including by
          limiting or discontinuing certain features of the Service),
          temporarily or permanently, without notice to you. FlightApp! will
          have no liability for any change to the Service or any suspension or
          termination of your access to or use of the Service.
        </li>
      </ul>
      <li>
        <b>13. Indemnity.</b> To the fullest extent permitted by law, you are
        responsible for your use of the Service, and you will defend, hold
        harmless and indemnify FlightApp! and its officers, directors,
        employees, consultants, affiliates, subsidiaries and agents (together,
        the FlightApp! Entities) from and against every claim brought by a third
        party, and any related liability, damage, loss, and expense, including
        reasonable attorneys fees and costs, arising out of or connected with:
        (a) your unauthorized use of, or misuse of, the Service; (b) your
        violation of any portion of these Terms, any representation, warranty,
        or agreement referenced in these Terms, or any applicable law or
        regulation; (c) your violation of any third party right, including any
        intellectual property right or publicity, confidentiality, other
        property, or privacy right; or (d) any dispute or issue between you and
        any third party. We reserve the right, at our own expense, to assume the
        exclusive defense and control of any matter otherwise subject to
        indemnification by you (without limiting your indemnification
        obligations with respect to that matter), and in that case, you agree to
        cooperate with our defense of those claims.
      </li>
      <li>
        <p>
          <b>14. Disclaimers; No Warranties</b>
        </p>
        <p>
          THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE
          SERVICE ARE PROVIDED AS IS AND ON AN AS AVAILABLE BASIS. FLIGHTAPP!
          DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
          RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE
          THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
          ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF
          COURSE OF DEALING, USAGE, OR TRADE. FLIGHTAPP! DOES NOT WARRANT THAT
          THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR CONTENT
          OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE OF
          ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND FLIGHTAPP! DOES NOT
          WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
        </p>

        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM THE SERVICE OR FLIGHTAPP! ENTITIES OR ANY MATERIALS OR CONTENT
          AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY
          OF THE FLIGHTAPP! ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED
          IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT
          FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU
          UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR
          OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY
          DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE
          DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA,
          INCLUDING USER CONTENT.
        </p>

        <p>
          THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION APPLY TO
          THE FULLEST EXTENT PERMITTED BY LAW. FlightApp! does not disclaim any
          warranty or other right that FlightApp! is prohibited from disclaiming
          under applicable law.
        </p>
      </li>
      <li>
        <p>
          <b>15. Limitation of Liability</b>
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE
          FLIGHTAPP! ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL,
          SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS
          OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR
          RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR
          USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER
          BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR
          ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY FLIGHTAPP! ENTITY HAS
          BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
        </p>

        <p>
          EXCEPT AS PROVIDED IN SECTION 16.5 AND TO THE FULLEST EXTENT PERMITTED
          BY LAW, THE AGGREGATE LIABILITY OF THE FLIGHTAPP! ENTITIES TO YOU FOR
          ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY
          TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE TERMS,
          WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF:
          (A) THE AMOUNT YOU HAVE PAID TO FLIGHTAPP! FOR ACCESS TO AND USE OF
          THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING
          RISE TO CLAIM; OR (B) $100.
        </p>

        <p>
          EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
          LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS
          INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER
          THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF
          THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE
          AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE
          LIMITATIONS IN THIS SECTION 15 WILL APPLY EVEN IF ANY LIMITED REMEDY
          FAILS OF ITS ESSENTIAL PURPOSE
        </p>
      </li>
      <li>
        <b>16. Dispute Resolution and Arbitration</b>
      </li>
      <ul>
        <li>
          <b>16.1 Generally.</b> In the interest of resolving disputes between
          you and FlightApp! in the most expedient and cost effective manner,
          and except as described in Section 16.2 and 16.3, you and FlightApp!
          agree that every dispute arising in connection with these Terms will
          be resolved by binding arbitration. Arbitration is less formal than a
          lawsuit in court. Arbitration uses a neutral arbitrator instead of a
          judge or jury, may allow for more limited discovery than in court, and
          can be subject to very limited review by courts. Arbitrators can award
          the same damages and relief that a court can award. This agreement to
          arbitrate disputes includes all claims arising out of or relating to
          any aspect of these Terms, whether based in contract, tort, statute,
          fraud, misrepresentation, or any other legal theory, and regardless of
          whether a claim arises during or after the termination of these Terms.
          YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND
          FLIGHTAPP! ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
          PARTICIPATE IN A CLASS ACTION.
        </li>
        <li>
          <b>16.2 Exceptions.</b> Despite the provisions of Section 16.1,
          nothing in these Terms will be deemed to waive, preclude, or otherwise
          limit the right of either party to: (a) bring an individual action in
          small claims court; (b) pursue an enforcement action through the
          applicable federal, state, or local agency if that action is
          available; (c) seek injunctive relief in a court of law in aid of
          arbitration; or (d) to file suit in a court of law to address an
          intellectual property infringement claim.
        </li>
        <li>
          <b>16.3 Opt-Out.</b> If you do not wish to resolve disputes by binding
          arbitration, you may opt out of the provisions of this Section 16
          within 30 days after the date that you agree to these Terms by sending
          a letter to FlightApp! d/b/a FlightApp!, Attention: Legal Department
          Arbitration Opt-Out,&nbsp;{businessAddress}&nbsp;that specifies: your
          full legal name, the email address associated with your account on the
          Service,, and a statement that you wish to opt out of arbitration ("
          <b>Opt-Out Notice</b>"). Once FlightApp! receives your Opt-Out Notice,
          this Section 16 will be void and any action arising out of these Terms
          will be resolved as set forth in Section 17.2. The remaining
          provisions of these Terms will not be affected by your Opt-Out Notice.
        </li>
        <li>
          <b>16.4 Arbitrator.</b> Any arbitration between you and FlightApp!
          will be settled under the Federal Arbitration Act and administered by
          the American Arbitration Association ("<b>AAA</b>") under its Consumer
          Arbitration Rules (collectively, "<b>AAA Rules</b>") as modified by
          these Terms. The AAA Rules and filing forms are available online at
          www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting
          FlightApp!. The arbitrator has exclusive authority to resolve any
          dispute relating to the interpretation, applicability, or
          enforceability of this binding arbitration agreement.
        </li>
        <li>
          <b>16.5 Notice of Arbitration; Process.</b> A party who intends to
          seek arbitration must first send a written notice of the dispute to
          the other party by certified U.S. Mail or by Federal Express
          (signature required) or, only if that other party has not provided a
          current physical address, then by electronic mail ("
          <b>Notice of Arbitration</b>"). FlightApp!'s address for Notice is:
          FlightApp! d/b/a FlightApp!,&nbsp;{businessAddress}. The Notice of
          Arbitration must: (a) describe the nature and basis of the claim or
          dispute; and (b) set forth the specific relief sought ("<b>Demand</b>
          "). The parties will make good faith efforts to resolve the claim
          directly, but if the parties do not reach an agreement to do so within
          30 days after the Notice of Arbitration is received, you or FlightApp!
          may commence an arbitration proceeding. All arbitration proceedings
          between the parties will be confidential unless otherwise agreed by
          the parties in writing. During the arbitration, the amount of any
          settlement offer made by you or FlightApp! must not be disclosed to
          the arbitrator until after the arbitrator makes a final decision and
          award, if any. If the arbitrator awards you an amount higher than the
          last written settlement amount offered by FlightApp! in settlement of
          the dispute prior to the award, FlightApp! will pay to you the lower
          of: (i) the amount awarded by the arbitrator; or (ii) $10,000.
        </li>
        <li>
          <b>16.6 Fees.</b> If you commence arbitration in accordance with these
          Terms, FlightApp! will reimburse you for your payment of the filing
          fee, unless your claim is for more than $10,000, in which case the
          payment of any fees will be decided by the AAA Rules. Any arbitration
          hearing will take place at a location to be agreed upon in&nbsp;
          {arbitrationCounty},&nbsp;{arbitrationState}, but if the claim is for
          $10,000 or less, you may choose whether the arbitration will be
          conducted: (a) solely on the basis of documents submitted to the
          arbitrator; (b) through a non-appearance based telephone hearing; or
          (c) by an in-person hearing as established by the AAA Rules in the
          county (or parish) of your billing address. If the arbitrator finds
          that either the substance of your claim or the relief sought in the
          Demand is frivolous or brought for an improper purpose (as measured by
          the standards set forth in Federal Rule of Civil Procedure 11(b)),
          then the payment of all fees will be governed by the AAA Rules. In
          that case, you agree to reimburse FlightApp! for all monies previously
          disbursed by it that are otherwise your obligation to pay under the
          AAA Rules. Regardless of the manner in which the arbitration is
          conducted, the arbitrator must issue a reasoned written decision
          sufficient to explain the essential findings and conclusions on which
          the decision and award, if any, are based. The arbitrator may make
          rulings and resolve disputes as to the payment and reimbursement of
          fees or expenses at any time during the proceeding and upon request
          from either party made within 14 days of the arbitrators ruling on the
          merits.
        </li>
        <li>
          <b>16.7 No Class Actions.</b> YOU AND FLIGHTAPP! AGREE THAT EACH MAY
          BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY
          AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
          REPRESENTATIVE PROCEEDING. Further, unless both you and FlightApp!
          agree otherwise, the arbitrator may not consolidate more than one
          persons claims, and may not otherwise preside over any form of a
          representative or class proceeding.
        </li>
        <li>
          <b>16.8 Modifications to this Arbitration Provision.</b> If FlightApp!
          makes any future change to this arbitration provision, other than a
          change to FlightApp!'s address for Notice of Arbitration, you may
          reject the change by sending us written notice within 30 days of the
          change to FlightApp!'s address for Notice of Arbitration, in which
          case your account with FlightApp! will be immediately terminated and
          this arbitration provision, as in effect immediately prior to the
          changes you rejected will survive.
        </li>
        <li>
          <b>16.9 Enforceability.</b> If Section 16.7 or the entirety of this
          Section 16 is found to be unenforceable, or if FlightApp! receives an
          Opt-Out Notice from you, then the entirety of this Section 16 will be
          null and void and, in that case, the exclusive jurisdiction and venue
          described in Section 17.2 will govern any action arising out of or
          related to these Terms.
        </li>
      </ul>
      <li>
        <b>17. Miscellaneous</b>
      </li>
      <ul>
        <li>
          <b>17.1 General Terms.</b> These Terms, together with the Privacy
          Policy and any other agreements expressly incorporated by reference
          into these Terms, are the entire and exclusive understanding and
          agreement between you and FlightApp! regarding your use of the
          Service. You may not assign or transfer these Terms or your rights
          under these Terms, in whole or in part, by operation of law or
          otherwise, without our prior written consent. We may assign these
          Terms at any time without notice or consent. The failure to require
          performance of any provision will not affect our right to require
          performance at any other time after that, nor will a waiver by us of
          any breach or default of these Terms, or any provision of these Terms,
          be a waiver of any subsequent breach or default or a waiver of the
          provision itself. Use of section headers in these Terms is for
          convenience only and will not have any impact on the interpretation of
          any provision. Throughout these Terms the use of the word including
          means including but not limited to. If any part of these Terms is held
          to be invalid or unenforceable, the unenforceable part will be given
          effect to the greatest extent possible, and the remaining parts will
          remain in full force and effect.
        </li>
        <li>
          <b>17.2 Governing Law.</b> These Terms are governed by the laws of the
          State of&nbsp;{lawState}&nbsp;without regard to conflict of law
          principles. You and FlightApp! submit to the personal and exclusive
          jurisdiction of the state courts and federal courts located
          within&nbsp;{businessCounty},&nbsp;{businessState}&nbsp;for resolution
          of any lawsuit or court proceeding permitted under these Terms. We
          operate the Service from our offices in&nbsp;
          {businessState}, and we make no representation that Materials included
          in the Service are appropriate or available for use in other
          locations.
        </li>
        <li>
          <b>17.3 Privacy Policy.</b> Please read the&nbsp;
          <ExternalLink
            label="FlightApp! Privacy Policy"
            to="https://managers.flightapp.io/Privacy"
          />
          &nbsp;carefully for information relating to our collection, use,
          storage, disclosure of your personal information. The FlightApp!
          Privacy Policy is incorporated by this reference into, and made a part
          of, these Terms.
        </li>
        <li>
          <b>17.4 Additional Terms.</b> Your use of the Service is subject to
          all additional terms, policies, rules, or guidelines applicable to the
          Service or certain features of the Service that we may post on or link
          to from the Service (the "<b>Additional Terms</b>"). All Additional
          Terms are incorporated by this reference into, and made a part of,
          these Terms.
        </li>
        <li>
          <b>17.5 Consent to Electronic Communications.</b> By using the
          Service, you consent to receiving certain electronic communications
          from us as further described in our Privacy Policy. Please read our
          Privacy Policy to learn more about our electronic communications
          practices. You agree that any notices, agreements, disclosures, or
          other communications that we send to you electronically will satisfy
          any legal communication requirements, including that those
          communications be in writing.
        </li>
        <li>
          <b>17.6 Contact Information.</b> The Service is offered by FlightApp!
          LLC d/b/a FlightApp!, located&nbsp;{businessAddress}. You may contact
          us by sending correspondence to that address or by emailing us
          at&nbsp;
          <SupportEmail />.
        </li>
        <li>
          <b>17.7 Notice to California Residents.</b> If you are a California
          resident, under California Civil Code Section 1789.3, you may contact
          the Complaint Assistance Unit of the Division of Consumer Services of
          the California Department of Consumer Affairs in writing at 1625 N.
          Market Blvd., Suite S-202, Sacramento, California 95834, or by
          telephone at (800) 952-5210 in order to resolve a complaint regarding
          the Service or to receive further information regarding use of the
          Service.
        </li>
        <li>
          <b>17.8 No Support.</b> We are under no obligation to provide support
          for the Service. In instances where we may offer support, the support
          will be subject to published policies.
        </li>
        <li>
          <b>17.9 International Use.</b> The Service is intended for visitors
          located within the United States. We make no representation that the
          Service is appropriate or available for use outside of the United
          States. Access to the Service from countries or territories or by
          individuals where such access is illegal is prohibited.
        </li>
      </ul>
      <li>
        <b>18. Notice Regarding Apple.</b> This Section 18 only applies to the
        extent you are using our mobile application on an iOS device. You
        acknowledge that these Terms are between you and FlightApp! only, not
        with Apple Inc. ("<b>Apple</b>"), and Apple is not responsible for the
        Service or the content thereof. Apple has no obligation to furnish any
        maintenance and support services with respect to the Service. If the
        Service fails to conform to any applicable warranty, you may notify
        Apple and Apple will refund any applicable purchase price for the mobile
        application to you; and, to the maximum extent permitted by applicable
        law, Apple has no other warranty obligation with respect to the Service.
        Apple is not responsible for addressing any claims by you or any third
        party relating to the Service or your possession and/or use of the
        Service, including: (a) product liability claims; (b) any claim that the
        Service fails to conform to any applicable legal or regulatory
        requirement; or (c) claims arising under consumer protection or similar
        legislation. Apple is not responsible for the investigation, defense,
        settlement and discharge of any third party claim that the Service
        and/or your possession and use of the Service infringe a third partys
        intellectual property rights. You agree to comply with any applicable
        third party terms when using the Service. Apple and Apples subsidiaries
        are third party beneficiaries of these Terms, and upon your acceptance
        of these Terms, Apple will have the right (and will be deemed to have
        accepted the right) to enforce these Terms against you as a third party
        beneficiary of these Terms. You hereby represent and warrant that: (i)
        you are not located in a country that is subject to a U.S. Government
        embargo, or that has been designated by the U.S. Government as a
        terrorist supporting country; and (ii) you are not listed on any U.S.
        Government list of prohibited or restricted parties.
      </li>
    </ul>
  </>
);

const PrivacyContent = () => (
  <>
    <p>FlightApp! Privacy Policy</p>
    <p>
      <b>Last Updated 25 January 2022</b>
    </p>
    <p>
      FlightApp! ("<b>we</b>", "<b>our</b>", and/or "<b>us</b>") values the
      privacy of individuals who use our website, the FlightApp app, and related
      services (collectively, our "<b>Services</b>"). This privacy policy ("
      <b>Privacy Policy</b>") explains how we collect, use, and share
      information from users of our Services. By using our Services, you agree
      to the collection, use, disclosure, and procedures this Privacy Policy
      describes. Beyond this Privacy Policy, your use of our Services is also
      subject to our Terms of Service.
    </p>
    <p>
      <u>
        <b>Information We Collect</b>
      </u>
    </p>
    <p>
      We may collect a variety of information from or about you or your devices
      from various sources, as described below.
    </p>
    <p>
      If you do not provide your information when requested, you may not be able
      to use our Services if that information is necessary to provide you with
      our Services or if we are legally required to collect it.
    </p>
    <p>
      <b>A. Information You Provide to Us.</b>
    </p>
    <p>
      <b>Registration and Profile Information.</b> When you sign up for an
      account to use our Services, we will ask you for your information,
      including your name, telephone number, and email address.
    </p>
    <p>
      <b>Communications.</b> If you contact us directly, we may receive
      additional information about you. For example, when you contact us for
      support, we will receive your name, email address, phone number, and the
      contents of your support issue along with any other information you chose
      to provide. If you subscribe to our marketing communications, we will
      collect certain information from you such as your email address. When we
      send you emails, we may track whether you open them to learn how to
      deliver a better customer experience and improve our Services.
    </p>
    <p>
      <b>Careers.</b> If you decide to apply for a job with us, you may submit
      your contact information and your resume online. We will collect the
      information you choose to provide on your resume, such as your education
      and employment experience. You may also apply through certain third-party
      websites. If you do so, we will collect the information you make available
      to us through those websites.
    </p>
    <p>
      <b>Content you Provide.</b> We collect the content, communications, and
      other information you provide when you use our Services, including any
      content you upload to, create, input, and/or share on our Services. For
      example, when you create an expense report on our Services, we will
      collect information about the expense report such as the date and time you
      create it, information you add to the expense report, information you
      provide regarding the reports recipients, and any additional information
      you provide in connection with the expense report, including any files you
      upload.
    </p>
    <p>
      <b>B. Information We Collect When You Use our Services.</b>
    </p>
    <p>
      <b>Location Information.</b> When you use our Services, if you allow us,
      we will receive your precise location information. We may also collect the
      precise location of your device when our Services are running in the
      foreground or background or when the app is closed. We may also infer your
      general location information, for example by using your internet protocol
      (IP) address.
    </p>
    <p>
      <b>Device Information.</b> We receive information about the device and
      software you use to access our Services, including IP address, web browser
      type, operating system version, phone carrier and manufacturer,
      application installations, device identifiers, geolocation information,
      mobile advertising identifiers, and push notification tokens.
    </p>
    <p>
      <b>Usage Information.</b> To help us understand how you use our Services
      and to help us improve them, we automatically receive information about
      your interactions with them, like the pages or other content you view, the
      content you post, the information you upload, the searches you conduct,
      the length of time you spend on a page, objects such as hyperlinks you
      click on, and the dates and times of your visits.
    </p>
    <p>
      <b>Information from Cookies and Similar Technologies.</b> We and our
      third-party partners may collect information using cookies, pixel tags, or
      similar technologies. Our third-party partners, such as analytics and
      advertising partners, may use these technologies to collect information
      about your online activities over time and across different services.
      Cookies are small text files containing a string of alphanumeric
      characters. We may use both session cookies and persistent cookies. A
      session cookie disappears after you close your browser. A persistent
      cookie remains after you close your browser and may be used by your
      browser on subsequent visits to our Services.
    </p>
    <p>
      Please review your web browsers "Help" file to learn the proper way to
      modify your cookie settings. Please note that if you delete or choose not
      to accept cookies from the Service, you may not be able to utilize the
      features of the Service to their fullest potential.
    </p>
    <p>
      <b>C. Information We Receive from Third Parties.</b> We may receive
      additional information about you from third parties such as data or
      marketing partners and combine it with other information we have about
      you.
    </p>
    <p>
      <u>
        <b>How We Use the Information We Collect</b>
      </u>
    </p>
    <p>We use the information we collect:</p>
    <ul>
      <li>To provide, maintain, improve, and enhance our Services;</li>
      <li>
        To personalize your experience on our Services such as by providing
        tailored content and recommendations;
      </li>
      <li>
        To understand and analyze how you use our Services and develop new
        products, services, features, and functionality;
      </li>
      <li>
        To communicate with you, provide you with updates and other information
        relating to our Services, provide information that you request, respond
        to comments and questions, and otherwise provide customer support;
      </li>
      <li>
        To facilitate the connection of third-party services or applications;
      </li>
      <li>
        For marketing and advertising purposes, such as developing and providing
        promotional and advertising materials that may be relevant, valuable, or
        otherwise of interest to you;
      </li>
      <li>To facilitate transactions and payments;</li>
      <li>
        To generate de-identified or aggregated data for our business purposes;
      </li>
      <li>To send you push notifications and text messages;</li>
      <li>
        To find and prevent fraud, and respond to trust and safety issues that
        may arise;
      </li>
      <li>
        For compliance purposes, including enforcing our Terms of Service or
        other legal rights, as may be required by applicable laws and
        regulations, or as requested by any judicial process or governmental
        agency; and
      </li>
      <li>
        For other purposes for which we provide specific notice at the time the
        information is collected.
      </li>
    </ul>
    <p>
      <u>
        <b>How We Share the Information We Collect</b>
      </u>
    </p>
    <p>
      We do not share or otherwise disclose information we collect from or about
      you except as described below or otherwise disclosed to you at the time of
      the collection.
    </p>
    <p>
      <b>Affiliates.</b> We may share any information we receive with our
      affiliates for any of the purposes described in this Privacy Policy.
    </p>
    <p>
      <b>Vendors and Service Providers.</b> We may share any information we
      receive with vendors and service providers retained in connection with the
      provision of our Services.
    </p>
    <p>
      <b>Your Communications with Third Parties.</b> When you use our Services
      to send information to third parties, we will share such information with
      the parties you designate as recipients. For example, if you complete and
      send an expense report to a third party, we will share all of the
      information you provide in connection with that expense report with the
      intended recipient.
    </p>
    <p>
      <b>Marketing.</b> We do not rent, sell, or share information about you
      with nonaffiliated companies for their direct marketing purposes unless we
      have your permission.
    </p>
    <p>
      <b>Analytics Partners.</b> We may use analytics services such as Google
      Analytics to collect and process certain analytics data. These services
      may also collect information about your use of other websites, apps, and
      online resources.
    </p>
    <p>
      <b>Advertising Partners.</b> We work with third party advertising partners
      to show you ads that we think may interest you. Some of our advertising
      partners are members of the Network Advertising Initiative (
      <ExternalLink to="https://optout.networkadvertising.org/?c=1#!/" />) or
      the Digital Advertising Alliance (
      <ExternalLink to="https://optout.aboutads.info/?c=2&lang=EN" />
      ). If you do not wish to receive personalized ads, please visit their
      opt-out pages to learn about how you may opt out of receiving web-based
      personalized ads from member companies. You can access any settings
      offered by your mobile operating system to limit ad tracking, or you can
      install the AppChoices mobile app to learn more about how you may opt out
      of personalized ads in mobile apps.
    </p>
    <p>
      <b>As Required by Law and Similar Disclosures.</b> We may access,
      preserve, and disclose your information if we believe doing so is required
      or appropriate to: (a) comply with law enforcement requests and legal
      process, such as a court order or subpoena; (b) respond to your requests;
      or (c) protect your, our, or others rights, property, or safety. For the
      avoidance of doubt, the disclosure of your information may occur if you
      send any objectionable content on or through our Services.
    </p>
    <p>
      <b>Merger, Sale, or Other Asset Transfers.</b> We may transfer your
      information to service providers, advisors, potential transactional
      partners, or other third parties in connection with the consideration,
      negotiation, or completion of a corporate transaction in which we are
      acquired by or merged with another company or we sell, liquidate, or
      transfer all or a portion of our assets.
    </p>
    <p>
      <b>Consent.</b> We may also disclose your information with your
      permission.
    </p>
    <p>
      <u>
        <b>Your Choices</b>
      </u>
    </p>
    <p>
      <b>Location Information.</b> You can prevent your device from sharing
      precise location information at any time through your devices operating
      system settings. However, location is core to our Services and without it,
      we may not be able to provide with all the functionality our Services.
    </p>
    <p>
      <b>Marketing Communications.</b> You can unsubscribe from our promotional
      emails via the link provided in the emails. Even if you opt-out of
      receiving promotional messages from us, you will continue to receive
      administrative messages from us.
    </p>
    <p>
      <b>Do Not Track.</b> There is no accepted standard on how to respond to Do
      Not Track signals, and we do not respond to such signals.
    </p>
    <p>
      <b>
        If you choose not to provide us with information we collect, some
        features of our Services may not work as intended.
      </b>
    </p>
    <p>
      <u>
        <b>Third Parties</b>
      </u>
    </p>
    <p>
      Our Services may contain links to other websites, products, or services
      that we do not own or operate. We are not responsible for the privacy
      practices of these third parties. Please be aware that this Privacy Policy
      does not apply to your activities on these third-party services or any
      information you disclose to these third parties. We encourage you to read
      their privacy policies before providing any information to them.
    </p>
    <p>
      <u>
        <b>Security</b>
      </u>
    </p>
    <p>
      We make reasonable efforts to protect your information by using physical
      and electronic safeguards designed to improve the security of the
      information we maintain. However, as no electronic transmission or storage
      of information can be entirely secure, we can make no guarantees as to the
      security or privacy of your information.
    </p>
    <p>
      <u>
        <b>Children's Privacy</b>
      </u>
    </p>
    <p>
      We do not knowingly collect, maintain, or use personal information from
      children under 13 years of age, and part of our Services are directed to
      children. If you learn that a child has provided us with personal
      information in violation of this Privacy Policy, you may alert us at&nbsp;
      <SupportEmail />.
    </p>
    <p>
      <u>
        <b>International Visitors</b>
      </u>
    </p>
    <p>
      Our Services are hosted in the United States and intended for visitors
      located within the United States. If you choose to use our Services from
      the European Union or other regions of the world with laws governing data
      collection and use that may differ from U.S. law, then please note that
      you are transferring your personal information outside of those regions to
      the United States for storage and processing. Also, we may transfer your
      data from the U.S. to other countries or regions in connection with
      storage and processing of data, fulfilling your requests, and operating
      the Services. By providing any information, including personal
      information, on or to our Services, you consent to such transfer, storage,
      and processing.
    </p>
    <p>
      <u>
        <b>Update Your Information or Pose a Question</b>
      </u>
    </p>
    <p>
      You can update your account and profile information through your profile
      settings. If you have questions about your privacy on our Services or this
      privacy policy, please contact us at&nbsp;
      <SupportEmail />.
    </p>
    <p>
      <u>
        <b>Changes to this Privacy Policy</b>
      </u>
    </p>
    <p>
      We will post any adjustments to the Privacy Policy on this page, and the
      revised version will be effective when it is posted. If we materially
      change the ways in which we use or share personal information previously
      collected from you through our Services, we will attempt to notify you
      through our Services, by email, or other means.
    </p>
    <p>
      <u>
        <b>Contact Information</b>
      </u>
    </p>
    <p>
      If you have any questions, comments, or concerns about our processing
      activities, please email us at&nbsp;
      <SupportEmail />
      &nbsp;or write to us at&nbsp;{businessAddress}.
    </p>
  </>
);

export default Terms;
