import React, { useCallback, useEffect, useState } from 'react';
import { Box, MainSurface, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import {
  SquawkStatus,
  updateSquawk,
  updateAircraftSquawkStatus,
} from '@appUtils/squawks';
import { List } from 'react-native-paper';
import styled from '@emotion/native';
import { View } from 'react-native';
import { TextFormField } from '@appComponents/forms/FormFields';
import { Controller, useForm } from 'react-hook-form';
import { Icon } from '@appComponents/theme';
import { DateTime } from 'luxon';
import Button from '@appComponents/Button';
import { Divider } from 'react-native-paper';
import _ from 'lodash';
import { useStorageImages } from '../utils/storage';
import { useTheme } from '@appComponents/theme';
import SearchField from '@appComponents/SearchField';
import ControlledImageViewer from './ControlledImageViewer';

export const AircraftSquawks = ({
  activeSquawks,
  archivedSquawks,
  archived = false,
  BackButton,
}) => {
  const squawks = archived ? archivedSquawks : activeSquawks;

  return (
    <MainSurface>
      {BackButton}
      <ScrollWrapper>
        <Box ph={2}>
          <SquawkListHeader />
          {squawks.map(squawk => (
            <SquawkContent
              key={squawk.path}
              squawk={squawk}
              activeSquawks={activeSquawks}
            />
          ))}
        </Box>
      </ScrollWrapper>
    </MainSurface>
  );
};

const widths = {
  title: 400,
  tripId: 100,
  reported: 100,
  priority: 100,
};

const SquawkContent = ({ squawk, activeSquawks }) => {
  const [expanded, setExpanded] = useState(false);
  const [editable, setEditable] = useState(false);
  const handlePress = () => setExpanded(!expanded);
  const defaultValues = {
    title: squawk?.title ?? '',
    description: squawk?.description ?? '',
    currentStatus: squawk?.currentStatus
      ? { value: squawk.currentStatus, label: squawk.currentStatus }
      : { value: SquawkStatus.UNDEFINED, label: SquawkStatus.UNDEFINED },
    status: squawk.status,
    photoUrls: squawk.photoUrls || [],
  };

  const {
    control,
    formState: { dirtyFields, isDirty, isSubmitSuccessful },
    getValues,
    handleSubmit,
    watch,
    reset,
  } = useForm({ defaultValues });

  const images = useStorageImages(watch('photoUrls'));

  const update = handleSubmit(
    useCallback(
      async payload => {
        if (isDirty) {
          const filteredPayload = {
            ...payload,
            currentStatus: payload.currentStatus.value,
          };
          await updateSquawk({
            payload: filteredPayload,
            squawkPath: squawk.path,
            statusChanged: dirtyFields.currentStatus,
          });
          if (dirtyFields.currentStatus) {
            await updateAircraftSquawkStatus({
              aircraftPath: _.first(_.split(squawk.path, '/squawks/')),
              squawks: activeSquawks,
              newSquawkStatus: getValues('currentStatus').value,
              squawkId: _.last(_.split(squawk.path, '/')),
            });
          }
        }
      },
      [
        activeSquawks,
        dirtyFields.currentStatus,
        getValues,
        isDirty,
        squawk.path,
      ],
    ),
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [getValues, isSubmitSuccessful, reset]);

  const accordionTitle = (
    <Row>
      <AccordionText w={widths.title}>{squawk.title ?? '-'}</AccordionText>
      <AccordionText w={widths.tripId}>
        {squawk.trip?.identifier ?? '-'}
      </AccordionText>
      <AccordionText w={widths.reported}>
        {squawk.dateCreated
          ? DateTime.fromSeconds(squawk.dateCreated.seconds).toLocaleString(
              DateTime.DATE_SHORT,
            )
          : '-'}
      </AccordionText>
      <AccordionText w={widths.priority}>
        {squawk.currentStatus ?? '-'}
      </AccordionText>
    </Row>
  );

  return (
    <>
      <SquawkAccordion
        title={accordionTitle}
        right={() => null}
        left={() => <ExpansionIcon name={expanded ? 'menu-up' : 'menu-down'} />}
        expanded={expanded}
        onPress={handlePress}>
        <SquawkBox ph={2}>
          <Spacer size={4} />
          <Row jc="flex-start">
            <Label text="TITLE" />
            <EditSlot
              editable={editable}
              setEditable={setEditable}
              update={update}
            />
          </Row>
          <TextField editable={editable} name="title" control={control} />
          <Spacer />
          <Label text="DESCRIPTION" />
          <TextField editable={editable} name="description" control={control} />
          <Spacer />
          <Label text="IMAGES" />
          <SectionDivider width="8%" />
          <ControlledImageViewer
            images={images}
            editable={editable}
            control={control}
          />
          <Spacer />
          <Label text="STATUS" />
          <SectionDivider />
          <StatusDropdown control={control} disabled={!editable} />
          <Spacer size={4} />
        </SquawkBox>
      </SquawkAccordion>
      <Spacer size={0.2} />
    </>
  );
};

const statusOptions = [
  { value: SquawkStatus.UNDEFINED, label: SquawkStatus.UNDEFINED },
  { value: SquawkStatus.MEL, label: SquawkStatus.MEL },
  { value: SquawkStatus.UNFLIGHTWORTHY, label: SquawkStatus.UNFLIGHTWORTHY },
  { value: SquawkStatus.ARCHIVED, label: SquawkStatus.ARCHIVED },
];

const StatusDropdown = ({ control, disabled }) => {
  const theme = useTheme();
  return (
    <Controller
      control={control}
      name="currentStatus"
      render={({ field: { onChange, value } }) => {
        return (
          <SelectWrap>
            <SearchField
              value={value}
              onChange={onChange}
              disabled={disabled}
              options={statusOptions}
              menuPortalTarget={document.body}
              controlStyle={getControlStyle(theme)}
            />
          </SelectWrap>
        );
      }}
    />
  );
};

const getControlStyle = theme => ({
  backgroundColor: theme.colors.background,
  color: theme.colors.text,
});

const IconSpacer = styled(View)`
  padding-horizontal: 22px;
`;

const SquawkListHeader = () => (
  <>
    <HeaderAccordion
      title={
        <Row>
          <AccordionText weight={700} w={widths.title}>
            TITLE
          </AccordionText>
          <AccordionText weight={700} w={widths.tripId}>
            TRIP ID
          </AccordionText>
          <AccordionText weight={700} w={widths.reported}>
            REPORTED
          </AccordionText>
          <AccordionText weight={700} w={widths.priority}>
            PRIORITY
          </AccordionText>
        </Row>
      }
      right={() => null}
      left={() => <IconSpacer />}
      expanded={false}
      onPress={() => null}>
      <></>
    </HeaderAccordion>
    <Spacer size={0.2} />
  </>
);

const HeaderAccordion = styled(List.Accordion)(({ theme }) => ({
  marginVertical: '-15px',
  pointerEvents: 'none',
  backgroundColor: theme.colors.tableBackground,
  justifyContent: 'space-between',
}));

const AccordionText = styled(Text)(({ w }) => ({
  width: w ?? 100,
  overflow: 'hidden',
}));

const ExpansionIcon = styled(Icon)`
  padding-horizontal: 14;
`;

const EditSlot = ({ editable, setEditable, update }) => {
  const editButton = (
    <Button
      icon="edit"
      mode="outlined"
      color="dark"
      ml={1}
      onPress={() => setEditable(true)}
    />
  );

  const saveButton = (
    <Button
      icon="check"
      mode="outlined"
      color="dark"
      ml={1}
      onPress={() => {
        update();
        setEditable(false);
      }}
    />
  );

  return editable ? saveButton : editButton;
};

const Row = styled(View)(({ jc }) => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
}));

const TextField = styled(TextFormField)(({ editable, theme }) => ({
  width: '60%',
  backgroundColor: editable ? 'transparent' : theme.colors.background,
}));

const SelectWrap = styled(View)`
  width: 35%;
`;

const ScrollWrapper = styled.ScrollView(({ theme }) => ({
  padding: theme.layout.space(3),
}));

const Label = ({ text }) => (
  <LabelWrap weight={700} size="smallest">
    {text}
  </LabelWrap>
);

const LabelWrap = styled(Text)`
  margin-left: 16px;
`;

const SquawkAccordion = styled(List.Accordion)`
  padding-bottom: 10px;
`;

const SectionDivider = styled(Divider)(({ theme, width }) => ({
  marginVertical: theme.layout.space(1),
  width: width ?? '80%',
}));

const SquawkBox = styled(Box)(({ theme }) => ({
  borderColor: theme.colors.background,
  borderWidth: 2,
  borderRadius: 5,
  marginTop: 1,
}));
