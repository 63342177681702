/**
 * @file
 * Utils related to accounting including formatting money and summing expenses
 *
 * @format
 * @flow strict-local
 */
import React from 'react';
import _ from 'lodash';
import { NumericFormat } from 'react-number-format';
import { UserRole } from '@appUtils/tripConverter';
import { isPicOnTrip, isSicOnTrip } from './trip';

export const getExpenseTotal = expenses => {
  let expenseTotal = 0;
  for (let e of expenses) {
    expenseTotal += e.amount;
  }
  return expenseTotal;
};

export const getExpenseTotals = (expenses, picName, sicName) => {
  let expenseTotal = 0;
  let totalDueFromOwner = 0;
  let totalDueToPilots = 0;
  let totalDueToPic = 0;
  let totalDueToSic = 0;
  for (let e of expenses) {
    expenseTotal += e.amount;
    if (e.paymentMethod?.billedToRole !== UserRole.OWNER) {
      totalDueFromOwner += e.amount;
      if (e.paymentMethod?.billedToRole === UserRole.PILOT) {
        totalDueToPilots += e.amount;
        if (e.paymentMethod.billedToName === picName) {
          totalDueToPic += e.amount;
        } else if (e.paymentMethod.billedToName === sicName) {
          totalDueToSic += e.amount;
        }
      }
    }
  }
  return {
    expenseTotal,
    totalDueFromOwner,
    totalDueToPilots,
    totalDueToPic,
    totalDueToSic,
  };
};

export const getUserTotals = (trips, users, userRole) => {
  const userTotals = { totalDue: 0 };
  if (users) {
    const userNames = getUserNames(users);
    // Make sure each user gets an entry, even if they don't have any trips
    for (let u of userNames) {
      if (!userTotals[u]) {
        userTotals[u] = { total: 0, userName: u };
      }
    }
  }
  if (trips) {
    const isPilot = userRole !== UserRole.OWNER;
    for (let trip of trips) {
      const tripName = `${trip?.identifier} - ${trip?.customName}`;
      const tripUsers = isPilot ? trip?.pilots : [trip?.owner];
      const userNames = getUserNames(tripUsers);
      const expenses = trip?.expenses ?? [];
      const adjustments = trip?.expenseAdjustments ?? [];
      // Add the user expenses for each trip
      for (let e of expenses) {
        if (
          (isPilot && e?.paymentMethod?.billedToRole === userRole) ||
          (!isPilot && e?.paymentMethod?.billedToRole !== userRole)
        ) {
          userTotals.totalDue += e.amount;
          const billedTo = isPilot
            ? e?.paymentMethod?.billedToName
            : userNames[0];
          if (userTotals[billedTo]) {
            userTotals[billedTo].total += e.amount;
            if (userTotals[billedTo][tripName]) {
              userTotals[billedTo][tripName].total += e.amount;
            } else {
              userTotals[billedTo][tripName] = {
                total: e.amount,
                path: trip?.path,
              };
            }
          } else {
            userTotals[billedTo] = {
              total: e.amount,
              userName: billedTo,
              [tripName]: {
                total: e.amount,
                path: trip?.path,
              },
            };
          }
        }
      }
      // Add the user adjustments for each trip
      for (let a of adjustments) {
        if (a?.dueToRole === userRole) {
          userTotals.totalDue += a.adjustment;
          const dueTo = a?.dueToName;
          if (userTotals[dueTo]) {
            userTotals[dueTo].total += a.adjustment;
            if (userTotals[dueTo][tripName]) {
              userTotals[dueTo][tripName].total += a.adjustment;
            } else {
              userTotals[dueTo][tripName] = {
                total: a.adjustment,
                path: trip?.path,
              };
            }
          } else {
            userTotals[dueTo] = {
              total: a.adjustment,
              userName: dueTo,
              [tripName]: {
                total: a.adjustment,
                path: trip?.path,
              },
            };
          }
        }
      }
      // Make sure each user on the trip gets an entry, even if it's 0
      for (let u of userNames) {
        if (!userTotals[u]) {
          userTotals[u] = { total: 0, userName: u };
        }
        if (!userTotals[u][tripName]) {
          userTotals[u][tripName] = { total: 0, path: trip?.path };
        }
      }
    }
  }
  return userTotals;
};

export const getUserNames = users => {
  const userNames = [];
  if (users) {
    for (let user of users) {
      userNames.push(`${user?.firstName} ${user?.lastName}`);
    }
  }
  return userNames;
};

export const groupExpenseAdjustments = (
  expenseAdjustments,
  ownerName,
  picName,
  sicName,
) => {
  const ownerAdjustments = [];
  const picAdjustments = [];
  const sicAdjustments = [];
  for (const eA of expenseAdjustments) {
    if (eA.dueToRole === UserRole.OWNER && eA.dueToName === ownerName) {
      ownerAdjustments.push(eA);
    } else if (eA.dueToRole === UserRole.PILOT) {
      if (eA.dueToName === picName) {
        picAdjustments.push(eA);
      } else if (eA.dueToName === sicName) {
        sicAdjustments.push(eA);
      }
    }
  }

  return { ownerAdjustments, picAdjustments, sicAdjustments };
};

export const sumAdjustments = expenseAdjustments => {
  let totalAdjustments = 0;
  for (let eA of expenseAdjustments) {
    totalAdjustments += eA.adjustment ?? 0;
  }
  return totalAdjustments;
};

export const parenthesizedNumberToNegative = number => {
  if (number === undefined) {
    return number;
  }
  let asString = number.toString();
  let addNegative = false;
  if (asString.length === 0) {
    return number;
  }
  if (asString.length === 1 && (asString[0] === '-' || asString[0] === '.')) {
    return 0;
  }
  if (asString[0] === '(') {
    asString = asString.substring(1);
    addNegative = true;
  }
  if (asString[asString.length - 1] === ')') {
    asString = asString.substring(0, asString.length - 1);
    addNegative = true;
  }
  if (asString.length === 0) {
    return 0;
  }
  if (addNegative) {
    asString = `-${asString}`;
  }
  return asString;
};

export const FormatDollars = ({
  dollars,
  prefix = '',
  suffix = '',
  renderText,
}) => (
  <NumericFormat
    value={dollars}
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator={true}
    displayType="text"
    prefix={`${prefix}$`}
    suffix={suffix}
    renderText={renderText}
  />
);

export const toMoneyString = num => {
  let output = num.toLocaleString();
  const pointIndex = output.indexOf('.');
  const strlen = output.length;
  if (pointIndex === -1) {
    output += '.00';
  } else if (pointIndex + 3 !== strlen) {
    output += '0';
  }
  return `$${output}`;
};

export const areTripExpensesClosedForPilot = (trip, user) => {
  if (trip.expensesComplete) {
    return true;
  }
  if (isPicOnTrip(trip, user)) {
    return trip?.picExpensesComplete;
  }
  if (isSicOnTrip(trip, user)) {
    return trip?.sicExpensesComplete;
  }
  return true;
};

const makePilotSortFunc = user => (tripA, tripB) => {
  const doneA = areTripExpensesClosedForPilot(tripA, user);
  const doneB = areTripExpensesClosedForPilot(tripB, user);
  if (doneA === doneB) {
    return 0;
  }
  if (doneB) {
    return -1;
  }
  return 1;
};

/**
 * Sorts the trips by whether they are complete for pilots or approved for owners.
 * @param {*} tripFetchFunc function for fetching the applicable trips
 * @param {*} role expects either UserRole.OWNER or UserRole.PILOT
 * @param {*} user user's full info
 * @returns sorted array of trips
 */
export const useTripsOrderedByCompleteForUser = (tripFetchFunc, role, user) => {
  const trips = tripFetchFunc();
  if (!role || !user) {
    return {
      loading: trips.loading,
      data: [],
    };
  }
  if (trips.loading) {
    return {
      loading: true,
      data: [],
    };
  }
  let data = [];
  if (role === UserRole.OWNER) {
    data = _.map(trips.data, trip => ({
      ...trip,
      expensesApproved:
        trip.expensesApproved === undefined ? false : trip.expensesApproved,
    }));
    data = _.orderBy(data, ['expensesApproved'], ['asc']);
  } else {
    data = trips.data.slice().sort(makePilotSortFunc(user));
  }
  return {
    loading: false,
    data,
  };
};
