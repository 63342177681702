import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import styled from '@emotion/native';

import {
  Header,
  MainSurface,
  ScreenLayout,
  ScreenLoader,
} from '@appComponents/ScreenLayout';
import { useTrip } from '@appUtils/manager';
import Tabs from '@appComponents/Tabs';
import TripDetails from './TripDetails';
import TripExpenses from './TripExpenses';
import BackToButton from '@webComponents/BackToButton';

const Trip = ({ route, navigation }) => {
  const documentPath = route.params.documentPath;
  const { name: fromName, ...fromParams } = { ...route.params?.from };
  const { loading, data: trip, save, update } = useTrip(documentPath);
  const edit = useCallback(
    () => navigation.navigate('Trip Builder', { documentPath }),
    [documentPath, navigation],
  );

  const [activeTab, setActiveTab] = useState(
    route.params?.tab ?? TripDetailsTab.TRIP,
  );

  return (
    <ScreenLayout alignItems="stretch">
      <TripInfoHeader>
        <TripTabs activeTab={activeTab} onChange={setActiveTab} />
      </TripInfoHeader>
      <MainSurface>
        {fromName && (
          <BackToButton
            backTo={fromName}
            params={fromParams}
            ml="auto"
            mr={1}
            mt={1}
          />
        )}
        <ScrollView>
          {loading && <ScreenLoader />}
          {!loading && activeTab === TripDetailsTab.TRIP && (
            <Details trip={trip} save={save} update={update} onEdit={edit} />
          )}
          {!loading && activeTab === TripDetailsTab.EXPENSES && (
            <TripExpenses trip={trip} save={save} update={update} />
          )}
        </ScrollView>
      </MainSurface>
    </ScreenLayout>
  );
};

export const TripDetailsTab = {
  TRIP: 'TRIP INFO',
  // AIRCRAFT: 'AIRCRAFT INFO',
  // SQUAWKS: 'SQUAWKS',
  // METERING: 'METERING',
  // POSTFLIGHT: 'POST FLIGHT',
  EXPENSES: 'EXPENSES',
};

const getTripRoutes = () => [
  { key: 'trip', title: TripDetailsTab.TRIP },
  // { key: 'aircraft', title: TripTab.AIRCRAFT },
  // { key: 'squawks', title: TripTab.SQUAWKS },
  // { key: 'metering', title: TripTab.METERING },
  // { key: 'postflight', title: TripTab.POSTFLIGHT },
  { key: 'expenses', title: TripDetailsTab.EXPENSES },
];

const TripTabs = ({ activeTab, onChange }) => {
  const [routes] = useState(getTripRoutes);
  const handleChange = useCallback(
    ({ route }) => onChange(route.title),
    [onChange],
  );

  return (
    <Tabs
      tabIndex={routes.findIndex(tab => tab.title === activeTab)}
      onTabPress={handleChange}
      routes={routes}
      tabBarInline
    />
  );
};

const TripInfoHeader = styled(Header)`
  padding-bottom: 0;
`;

const Details: typeof TripDetails = styled(TripDetails)(({ theme }) => ({
  paddingHorizontal: '5%',
  paddingVertical: theme.layout.space(2),
}));

export default Trip;
