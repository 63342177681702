import React, { Node, useState } from 'react';
import styled from '@emotion/native';
import { useForm } from 'react-hook-form';
import { useNavigation } from '@react-navigation/native';

import {
  useCreateAccountCallback,
  useCreateAccountAndCompanyCallback,
} from '@appUtils/auth';
import { UserRole } from '@appUtils/tripConverter';
import {
  CodeField,
  EmailFormField,
  FirstNameFormField,
  LastNameFormField,
  PasswordFormField,
  PhoneNumberFormField,
  CompanyNameFormField,
} from '@appComponents/forms/FormFields';
import { Box } from '@appComponents/ScreenLayout';
import Notification from '@appComponents/Notification';
import Button from '@appComponents/Button';
import Checkbox from '@appComponents/Checkbox';
import Text from '@appComponents/Text';
import TextLink from '@mobileComponents/TextLink';

export const CreateAccountForm = (): Node => {
  const form = useForm({
    defaultValues: {
      role: UserRole.MANAGER,
    },
  });

  const signUpCode = form.watch('signUpCode');
  const showProfile = signUpCode?.length === 5;

  return (
    <>
      <CodeField control={form.control} />
      {!showProfile && (
        <Text lh={20}>
          Contact your aircraft management company to get your sign up code. For
          support contact us at{' '}
          <TextLink
            label="takeoff@flightapp.io"
            // NOT WORKING FOR WEB
            href="mailto:takeoff@flightapp.io"
          />
          .
        </Text>
      )}

      {showProfile && <ProfileInfo form={form} />}
    </>
  );
};

export const CreateCompanyForm = (): Node => {
  const form = useForm({
    defaultValues: {
      role: UserRole.MANAGER,
    },
  });

  return <ProfileInfo form={form} isCompany />;
};

const ProfileInfo = ({ form, isCompany = false }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const createAccountAndCompanyCallback = useCreateAccountAndCompanyCallback(
    form,
    setIsSubmitted,
  );
  const createAccountCallback = useCreateAccountCallback(form, setIsSubmitted);
  const submit = isCompany
    ? createAccountAndCompanyCallback
    : createAccountCallback;
  const { navigate } = useNavigation();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const error = form.formState.errors.general;

  return (
    <>
      {isCompany && <CompanyNameFormField control={form.control} />}
      <FirstNameFormField control={form.control} />
      <LastNameFormField control={form.control} />
      <EmailFormField control={form.control} />
      <PhoneNumberFormField control={form.control} />
      <PasswordFormField control={form.control} />
      <Box ml={-2} dir="row" ai="center">
        <Checkbox
          label
          checked={termsAccepted}
          onChange={() => setTermsAccepted(!termsAccepted)}
        />
        <Text>
          <Text>Accept </Text>
          <Text color="secondary" onPress={() => navigate('Terms')}>
            Terms & Conditions
          </Text>
          <Text> and </Text>
          <Text color="secondary" onPress={() => navigate('Privacy')}>
            Privacy Policy
          </Text>
        </Text>
      </Box>

      <SubmitButton
        loading={form.formState.isSubmitting}
        onPress={submit}
        disabled={!termsAccepted || isSubmitted}>
        {isCompany ? 'Create Company and Account' : 'Create Account'}
      </SubmitButton>

      <Notification
        color="error"
        visible={Boolean(error)}
        onDismiss={form.clearErrors}>
        {error?.message}
      </Notification>
    </>
  );
};

const SubmitButton = styled(Button)(({ theme }) => ({
  padding: theme.layout.space(1),
}));
