/**
 * @file
 * List of users (pilots or owners) requesting access to the management company
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';

import {
  MainSurface,
  ScreenLayout,
  ScreenLoader,
} from '@appComponents/ScreenLayout';
import { getUserData } from '@appUtils/api';
import { UserRole } from '@appUtils/tripConverter';
import {
  Cell,
  NameCell,
  PhoneCell,
  PlainCell,
  UsersTable,
} from '../../components/TableCells';
import { Menu } from 'react-native-paper';
import Button from '@appComponents/Button';
import { useMenuState } from '@appComponents/Menu';
import { useAsyncCallback } from 'react-async-hook';
import {
  AccountState,
  useAccountRequestHandler,
  useAccountRequests,
} from '@appUtils/manager';

const AccountRequests = () => (
  <ScreenLayout alignItems="stretch">
    <MainSurface>
      <UserList />
    </MainSurface>
  </ScreenLayout>
);

const UserList = () => {
  const { data: requests, loading } = useAccountRequests();

  if (loading) {
    return <ScreenLoader />;
  }

  return (
    <UsersTable list={requests} loading={loading} listType="account requests">
      <NameCell title="Name" flex={6} />
      <PlainCell title="Email" path="email" flex={6} />
      <PhoneCell title="Phone" flex={4} />
      <RoleCell title="Role" flex={2} />
      <PlainCell title="Status" path="state" flex={2} />
      <ActionsCell name="actions" numeric flex={1} icon="more-dots" />
    </UsersTable>
  );
};

const RoleCell = ({ item: user, ...cellProps }) =>
  Boolean(user) && (
    <Cell {...cellProps}>
      {user.role[0].toUpperCase()}
      {user.role.slice(1)}
    </Cell>
  );

// User data is not immediately accessible after accepting them, wait for it
// then navigate to the passenger details screen
const navigateWhenUserReady = ({ user, navigate }) =>
  setTimeout(() => {
    getUserData(user.uid)
      .then(passenger => {
        navigate('Passenger Details', {
          userId: user.uid,
          title: `${user.firstName} ${user.lastName}`,
          from: { name: 'Account Requests' },
        });
      })
      .catch(e => {
        navigateWhenUserReady({ user, navigate });
      });
  }, 250);

const ActionsCell = ({ item: user, ...cellProps }) => {
  const { open, isOpen, close } = useMenuState();
  const { navigate } = useNavigation();
  const handleRequest = useAccountRequestHandler(user);
  const [passengerAdded, setPassengerAdded] = useState();
  const action = useAsyncCallback((state, role) => {
    close();
    handleRequest(state, role);
    if (
      role === UserRole.PASSENGER ||
      (!role &&
        state === AccountState.ACCEPTED &&
        user.role === UserRole.PASSENGER)
    ) {
      setPassengerAdded(true);
    }
  });

  useEffect(() => {
    if (passengerAdded && action.status === 'success') {
      navigateWhenUserReady({ user, navigate });
    }
  }, [action.status, navigate, passengerAdded, user]);

  const anchor = (
    <Button
      mode="text"
      icon="more-dots"
      loading={action.loading}
      onPress={open}
    />
  );

  return (
    <Cell {...cellProps}>
      <Menu anchor={anchor} visible={isOpen} onDismiss={close}>
        <Menu.Item
          title="Accept"
          onPress={() => action.execute(AccountState.ACCEPTED)}
        />
        {user.role !== UserRole.OWNER && (
          <Menu.Item
            title="Accept as Owner"
            onPress={() =>
              action.execute(AccountState.ACCEPTED, UserRole.OWNER)
            }
          />
        )}
        {user.role !== UserRole.PILOT && (
          <Menu.Item
            title="Accept as Pilot"
            onPress={() =>
              action.execute(AccountState.ACCEPTED, UserRole.PILOT)
            }
          />
        )}
        {user.role !== UserRole.PASSENGER && (
          <Menu.Item
            title="Accept as Passenger"
            onPress={() =>
              action.execute(AccountState.ACCEPTED, UserRole.PASSENGER)
            }
          />
        )}
        {user.role !== UserRole.MANAGER && (
          <Menu.Item
            title="Accept as Manager"
            onPress={() =>
              action.execute(AccountState.ACCEPTED, UserRole.MANAGER)
            }
          />
        )}
        {user.state !== AccountState.REJECTED && (
          <Menu.Item
            title="Reject"
            onPress={() => action.execute(AccountState.REJECTED)}
          />
        )}
      </Menu>
    </Cell>
  );
};

export default AccountRequests;
