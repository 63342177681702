/**
 * @file
 * A component displaying Trip information
 *
 * @format
 * @flow strict-local
 */

import React, { useCallback, useState } from 'react';
import { ViewStyle } from 'react-native';
import styled from '@emotion/native';
import { useNavigation } from '@react-navigation/native';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { TextInput } from 'react-native-paper';

import {
  Box,
  SectionBody,
  SectionHeader,
  Spacer,
} from '@appComponents/ScreenLayout';
import Text, { PersonText } from '@appComponents/Text';
import Button from '@appComponents/Button';
import DataTable, { Cell, PlainCell } from '@appComponents/DataTable';
import ProgressTracker from '@appComponents/ProgressTracker';
import { TripState, TripTab } from '@appUtils/tripConverter';
import * as Manager from '@appUtils/manager';
import app from '@appFirebase';
import TripMessages, { MessagesButton } from '@webComponents/TripMessages';
import { minutesToFlightTime } from '@appUtils/trip';
import { fuelUsedDisplay } from '@appUtils/numbers';
import { useAircraftData } from '@appUtils/aircraft';
import { useTripDocumentsDialog } from '@webComponents/TripDocuments';

type TripDetailsProps = {
  trip: Object,
  save: Function,
  update: Function,
  onEdit: string => void,
  style?: ViewStyle,
};

const TripDetails = ({
  trip,
  save,
  update,
  onEdit,
  style,
}: TripDetailsProps) => {
  const [messagesVisible, setMessagesVisible] = useState(false);

  return (
    <Box pv={1} style={style}>
      <TripInfoSection
        trip={trip}
        onEdit={onEdit}
        saveTrip={save}
        updateTrip={update}
        messagesVisible={messagesVisible}
        setMessagesVisible={setMessagesVisible}
      />
      <Spacer size={3} />
      <LegDetailsSection trip={trip} />
      <RightAlignedMessageBox>
        <TripMessages
          visible={messagesVisible}
          onClose={() => setMessagesVisible(false)}
          trip={trip}
        />
      </RightAlignedMessageBox>
    </Box>
  );
};

const TripInfoSection = ({
  trip,
  onEdit,
  saveTrip,
  updateTrip,
  messagesVisible,
  setMessagesVisible,
}) => {
  const messageIcon = messagesVisible ? 'arrow-drop-up' : 'arrow-drop-down';
  const steps = getProgressSteps(trip);
  const { navigate } = useNavigation();
  const tripId = trip?.id;
  const { dialogNode, openDialog } = useTripDocumentsDialog({
    saveTrip,
    updateTrip,
    tripDocuments: trip?.documents,
    tripId,
  });

  const backToTrips = useCallback(() => {
    const tab = _.get(
      {
        [TripState.OWNER_REQUEST]: TripTab.REQUESTED,
        [TripState.DRAFT]: TripTab.DRAFT,
        [TripState.UPCOMING]: TripTab.UPCOMING,
        [TripState.ACTIVE]: TripTab.ACTIVE,
        [TripState.ENDED]: TripTab.COMPLETED,
        [TripState.CANCELLED]: TripTab.COMPLETED,
      },
      trip.state,
      TripTab.DRAFT,
    );

    navigate('Trips', { tab });
  }, [navigate, trip.state]);

  const unreadMessages = trip?.conversation?.managementUnreadMessages;

  return (
    <>
      {dialogNode}
      <SectionHeader>
        <Text color="dark" weight={500}>
          TRIP INFO
        </Text>
        {!trip.archived && (
          <Button
            mode="outlined"
            icon="edit"
            color="dark"
            ml={1}
            onPress={onEdit}
          />
        )}
        <Button ml="auto" onPress={openDialog}>
          Trip Documents
        </Button>
        <MessagesButton
          messageIcon={messageIcon}
          trip={trip}
          messagesVisible={messagesVisible}
          setMessagesVisible={setMessagesVisible}
          unreadMessages={unreadMessages}
          ml={1}
        />
        <Button ml={1} onPress={backToTrips}>
          Back to Trips
        </Button>
      </SectionHeader>
      <SectionBody>
        <DataTable data={[trip]}>
          <PlainCell title="Tail #" path="aircraft.tailNumber" />
          <PlainCell title="Type" path="aircraft.type" />
          <NameCell title="Pilot in Command" path="pilots.0" />
          <NameCell title="Second in Command" path="pilots.1" />
          <PlainCell title="Notes" path="notes" />
        </DataTable>

        <Spacer />

        <Text size="smallest" weight="bold">
          {trip.status?.toUpperCase()}
        </Text>
        <LocationTracker>
          <ProgressTracker steps={steps} currentStep={steps.length} />
        </LocationTracker>
      </SectionBody>
    </>
  );
};

const NameCell = ({ item, path, flex }) => (
  <Cell flex={flex}>
    <PersonText entry={_.get(item, path)} />
  </Cell>
);

const LegDetailsSection = ({ trip }) => {
  const [aircraft] = useAircraftData(trip?.aircraft?.path);
  const updateLeg = useCallback(
    (updates, legIndex) => {
      const partial = {
        legs: trip.legs.map((leg, i) =>
          i === legIndex ? { ...leg, ...updates } : leg,
        ),
      };

      const update = Manager.getPayload(partial, trip);

      return app.firestore().doc(trip.path).update(update);
    },
    [trip],
  );

  return (
    <>
      <SectionHeader>
        <Text color="dark" weight={500}>
          LEG DETAILS
        </Text>
      </SectionHeader>
      <SectionBody>
        {trip.legs.map((leg, index) => (
          <Leg
            key={index}
            leg={leg}
            title={`LEG ${index + 1}`}
            update={payload => updateLeg(payload, index)}
            fuelUnits={aircraft?.fuelUnits ?? 'gal'}
          />
        ))}
      </SectionBody>
    </>
  );
};

const Leg = ({ leg, update, title = 'Leg', fuelUnits }) => {
  const [editMode, setEditMode] = useState(false);
  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      fuelOn: leg.fuelOn || 0,
      fuelOff: leg.fuelOff || 0,
    },
  });

  const edit = useCallback(() => setEditMode(true), []);

  const save = useCallback(
    payload => {
      setEditMode(false);
      return update(payload);
    },
    [update],
  );

  const { fuelOn, fuelOff } = watch();
  const fuelUsed = fuelUsedDisplay(fuelOn, fuelOff, '-');

  return (
    <>
      <Spacer />

      <LegTitle>
        <Text>{title.toUpperCase()}</Text>
      </LegTitle>

      <Spacer />

      <Text size="medium" color="dark" weight={500}>
        {leg.from} {'>'} {leg.to}
      </Text>

      <Spacer />

      <DataTable data={[leg]}>
        <DepartureCell title="Departure" flex={9} />
        <FlightTimeCell numeric title="Flight Time" flex={5} />
        <FuelUsedCell
          numeric
          fuelUsed={fuelUsed}
          title={`Fuel Used (${fuelUnits})`}
          flex={5}
        />
        <FuelInputCell
          numeric
          title={`Fuel On (${fuelUnits})`}
          name="fuelOn"
          editMode={editMode}
          control={control}
          flex={5}
        />
        <FuelInputCell
          numeric
          title={`Fuel Off (${fuelUnits})`}
          name="fuelOff"
          editMode={editMode}
          control={control}
          flex={5}
        />
        <EditCell
          numeric
          onEdit={edit}
          onSave={handleSubmit(save)}
          editMode={editMode}
          flex={2}
        />
      </DataTable>

      <Spacer />

      <SectionHeader>
        <Text color="dark" weight={500}>
          PASSENGERS
        </Text>
      </SectionHeader>

      <SectionBody>
        {leg.passengers.map((passenger, i) => (
          <LegPassenger key={i} passenger={passenger} isLead={i === 0} />
        ))}
      </SectionBody>

      <Spacer size={2} />
    </>
  );
};

const DepartureCell = ({ item, ...cellProps }) => {
  const departureDate = item?.departureDate;
  if (!departureDate) {
    return <Cell {...cellProps}>-</Cell>;
  }

  const date = departureDate.toLocaleString(DateTime.DATE_SHORT);
  const time = departureDate.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);

  return <Cell {...cellProps}>{[date, time].join(' @ ')}</Cell>;
};

const FlightTimeCell = ({ item, ...cellProps }) => {
  const flightTime = item.flightTime;
  if (!flightTime) {
    return <Cell {...cellProps}>-</Cell>;
  }

  return (
    <Cell {...cellProps}>{minutesToFlightTime(flightTime, 'explicit')}</Cell>
  );
};

const FuelUsedCell = ({ fuelUsed, ...cellProps }) => (
  <Cell {...cellProps}>{fuelUsed || '-'}</Cell>
);

const FuelInputCell = ({
  item,
  name,
  control,
  editMode = false,
  ...cellProps
}) => (
  <Cell {...cellProps}>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur } }) =>
        editMode ? (
          <FuelInput
            dense
            autoFocus={name === 'fuelOn'}
            mode="outlined"
            value={value}
            onBlur={onBlur}
            onChangeText={text => {
              if (!text) {
                onChange('');
                return;
              }

              // Only include numbers, but allow ending with a decimal point
              //  so it doesn't get cut off by number coercion.
              if (text.match(/^\d+\.?\d{0,1}$/)) {
                onChange(text);
              }
            }}
          />
        ) : (
          value || '-'
        )
      }
    />
  </Cell>
);

const EditCell = ({ onEdit, onSave, editMode = false, ...cellProps }) => (
  <Cell {...cellProps}>
    {editMode ? (
      <Button mode="outlined" icon="check" color="primary" onPress={onSave} />
    ) : (
      <Button mode="outlined" icon="edit" color="dark" onPress={onEdit} />
    )}
  </Cell>
);

const LegPassenger = ({ passenger, isLead }) => (
  <>
    <Spacer size={0.75} />
    <Text size="medium" color="dark">
      {passenger.name}
      {isLead && ' (Lead)'}
    </Text>
  </>
);

const getProgressSteps = trip => [
  _.head(trip.legs).from,
  ...trip.legs.map(leg => leg.to),
];

const LegTitle = styled.View`
  flex-direction: row;
  align-items: center;
`;

const LocationTracker = styled.View`
  max-width: 30%;
`;

const RightAlignedMessageBox = styled.View`
  position: absolute;
  right: 0;
  top: 90px;
  z-index: 1;
`;

const FuelInput: typeof TextInput = styled(TextInput)(({ theme }) => ({
  textAlign: 'right',
  marginLeft: '25%',
  width: '75%',
  marginTop: theme.layout.gap(-1.5),
}));

export default TripDetails;
