/**
 * @file
 * List of users (pilots or owners) and contact information
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useAsyncCallback } from 'react-async-hook';
import { Menu } from 'react-native-paper';
import _ from 'lodash';

import Button from '@appComponents/Button';
import { useMenuState } from '@appComponents/Menu';
import { ScreenLoader } from '@appComponents/ScreenLayout';
import {
  useCompanyData,
  useCompanyUsers,
  useCompanyUsersFull,
} from '@appUtils/api';
import { useUserAircraft } from '@appUtils/aircraft';
import { UserRole } from '@appUtils/tripConverter';
import { Cell, NameCell, PhoneCell, PlainCell, UsersTable } from './TableCells';

const UserSupplier = ({ company, roles, users, setUsers }) => {
  const { data } = useCompanyUsersFull({ roles, company });
  if (data && !_.isEmpty(data) && !_.isEqual(data, users)) {
    setUsers(data);
  }

  return null;
};

const UserList = ({
  role = UserRole.OWNER,
  withActions = false,
}: UserListProps) => {
  const [company, companyLoading] = useCompanyData();
  const [users, setUsers] = useState([]);
  const [owners, ownersLoading] = useCompanyUsers(UserRole.OWNER);
  const waitForOwners = role === UserRole.PASSENGER && ownersLoading;
  const roles =
    role === UserRole.MANAGER ? [UserRole.MANAGER, UserRole.EMPLOYEE] : [role];
  if (!company?.id || companyLoading || waitForOwners) {
    return <ScreenLoader />;
  }

  return (
    <>
      <UserSupplier
        company={company}
        roles={roles}
        users={users}
        setUsers={setUsers}
      />
      <UsersTable list={users} listType={role}>
        <NameCell title="Name" flex={40} />
        <PlainCell title="Email" path="email" flex={30} />
        <PhoneCell title="Phone" flex={15} />
        {role === UserRole.PASSENGER && (
          <OwnersCell title="Owners" owners={owners} flex={15} />
        )}
        {role !== UserRole.PASSENGER && (
          <AircraftCell title="Aircraft" role={role} flex={15} />
        )}
        {withActions && (
          <ActionsCell
            numeric
            name="actions"
            icon="more-dots"
            role={role}
            flex={3}
          />
        )}
      </UsersTable>
    </>
  );
};

const AircraftCell = ({ item: user = {}, role = '', flex }) => {
  const { data = [] } = useUserAircraft({ id: user?.id, role });

  return (
    <Cell flex={flex}>
      {_.isEmpty(data) && '-'}
      {_.map(data, craft => (
        <>
          {craft.tailNumber}
          <br />
        </>
      ))}
    </Cell>
  );
};

const OwnersCell = ({ item: user = {}, owners, flex }) => {
  const userOwners = user?.ownerIds?.map(id => {
    const owner = _.find(owners, o => o.id === id);
    return `${owner?.firstName} ${owner?.lastName}`;
  });

  return (
    <Cell flex={flex}>
      {_.isEmpty(userOwners)
        ? '-'
        : userOwners.map(u => (
            <>
              {u}
              <br />
            </>
          ))}
    </Cell>
  );
};

const ActionsCell = ({ item: user, role, ...cellProps }) => {
  const { open, isOpen, close } = useMenuState();
  const { actions, onAction } = useActions(role);

  const action = useAsyncCallback(action => {
    close();
    return onAction({ user, action });
  });

  const anchor = (
    <Button
      mode="text"
      icon="more-dots"
      loading={action.loading}
      onPress={open}
    />
  );

  return (
    <Cell {...cellProps}>
      <Menu anchor={anchor} visible={isOpen} onDismiss={close}>
        {_.map(actions, title => (
          <Menu.Item
            key={title}
            title={title}
            onPress={() => action.execute(title)}
          />
        ))}
      </Menu>
    </Cell>
  );
};

const useActions = role => {
  const { navigate } = useNavigation();

  const onAction = useCallback(
    ({ action, user }) => {
      switch (action) {
        case 'Edit':
          navigate(`${_.capitalize(role)} Details`, {
            userId: user.id,
            title: `${user.firstName} ${user.lastName}`,
          });
          break;
        default:
          throw new Error(`Unhandled action: "${action}"`);
      }
    },
    [navigate, role],
  );

  return {
    actions: ['Edit'],
    onAction,
  };
};

type UserListProps = {
  role: 'owner' | 'employee' | 'manager' | 'pilot',
  withActions?: boolean,
};

export default UserList;
